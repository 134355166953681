import { Box, Button, Text } from "@chakra-ui/react";
import { QueryClientProvider, useQuery } from "react-query";
import { AppInfoResponse } from "../../../services/atomus-internal-apis/atomus-internal-apis.types";
import SpModalProvider from "../../../contexts/SpModal";
import PlatformOverview from "./PlatformOverview";
import ConfigOverview from "./ConfigOverview";
import useMicrosoftToken from "../../../hooks/tokens.hooks";
import { SupportedPlatforms } from "../../../services/atomus-internal-apis/atomus-internal-apis.constants";
import { getTenantAppInfo } from "../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { useTenantAppInfo } from "../../../hooks/tenants.hooks";
import { useDevices } from "../../../hooks/devices.hooks";
import { createQueryClient } from "../../../utils/sections.utils";
import LastScrollableContainer from "../../../Components/LastScrollableContainer";

interface IUpdatesSectionProps {
  tenantId: string;
}

const updatesQueryClient = createQueryClient();
export default function UpdatesSection({ tenantId }: IUpdatesSectionProps) {
  const { getInternalApiToken } = useMicrosoftToken();

  const releaseAppInfo = useQuery<AppInfoResponse, Error>(
    ["release-app-info", SupportedPlatforms.ALL],
    async () => {
      const token = await getInternalApiToken();
      return getTenantAppInfo(token, "global", SupportedPlatforms.ALL);
    }
  );

  const tenantAppInfo = useTenantAppInfo(tenantId);
  const deviceInfo = useDevices(tenantId);

  const refetchData = () => {
    const options = { throwOnError: true };
    updatesQueryClient.refetchQueries(undefined, options);
    deviceInfo.refetch(options);
    releaseAppInfo.refetch(options);
    tenantAppInfo.refetch(options);
  };

  return (
    <QueryClientProvider client={updatesQueryClient}>
      <SpModalProvider>
        <LastScrollableContainer>
          <Box marginY={4}>
            {releaseAppInfo.isError && (
              <Text color="red">
                Error getting release app info: {releaseAppInfo.error.message}
              </Text>
            )}
            {tenantAppInfo.isError && (
              <Text color="red">
                Error getting tenant app info: {tenantAppInfo.error.message}
              </Text>
            )}
            {!releaseAppInfo.isError && !tenantAppInfo.isError && (
              <>
                <Button
                  size="sm"
                  colorScheme="blue"
                  variant="ghost"
                  marginRight={4}
                  zIndex="1"
                  float="right"
                  onClick={refetchData}
                >
                  Refresh
                </Button>
                <ConfigOverview tenantId={tenantId} />
                <PlatformOverview
                  platform={SupportedPlatforms.WIN}
                  releaseAppInfoQuery={releaseAppInfo}
                  tenantAppInfoQuery={tenantAppInfo}
                />
                <PlatformOverview
                  platform={SupportedPlatforms.MAC}
                  releaseAppInfoQuery={releaseAppInfo}
                  tenantAppInfoQuery={tenantAppInfo}
                />
                <PlatformOverview
                  platform={SupportedPlatforms.LINUX}
                  releaseAppInfoQuery={releaseAppInfo}
                  tenantAppInfoQuery={tenantAppInfo}
                />
              </>
            )}
          </Box>
        </LastScrollableContainer>
      </SpModalProvider>
    </QueryClientProvider>
  );
}
