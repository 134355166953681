import {
  DeviceOnboardingStatus,
  SupportedPlatforms,
} from "./atomus-internal-apis.constants";
import {
  ErrorAegisResponse,
  OptionalFeatureId,
  SuccessAegisResponse,
} from "../../types/api.types";

/**
 * @description statuses for the company branding images
 */
export interface CompanyBrandingImages {
  logo: boolean;
  loginBackground: boolean;
}

/**
 * @description a registered device on a tenant
 */
export type Device = {
  displayName: string;
  id: string;
  deviceId?: string;
  managedDeviceId?: string;
  deviceName: string;
  isCompliant: boolean;
  isManagement: string;
  managementType: string;
  operatingSystem: string;
  approximateLastSignInDateTime: string | null;
  enrollmentType: string;
  registeredUsers?: RegisteredUsersData[];
  mdmAppId?: string | null;
  trustType?: string;
  accountEnabled?: boolean;
};

/**
 * @description a user associated with an intune device
 */
export interface RegisteredUsersData {
  id: string;
  displayName: string;
  mail: string;
  userPrincipalName: string;
  existsInDB: boolean;
  active?: boolean;
}

/**
 * @description file information from latest.yml for an app version
 */
type AppInfoFileData = {
  url: string;
  sha512: string;
  size: number;
};

/**
 * @description app info for a current version parsed from latest.yml
 */
export type AppInfo = {
  version: string;
  stagingPercentage?: number;
  files: Array<AppInfoFileData>;
  path: string;
  sha512: string;
  releaseDate: string;
  downloadLink: string;
};

/**
 * @description supported platform
 */
export type SupportedPlatform =
  (typeof SupportedPlatforms)[keyof typeof SupportedPlatforms];

/**
 * @description response when requesting current app info
 */
export type AppInfoResponse = Record<
  SupportedPlatform,
  SuccessAegisResponse<AppInfo> | ErrorAegisResponse<any>
>;

/**
 * @description response data when staging percentage is successfully updated on a tenant
 */
export type UpdateStagingPercentageResponse = {
  staginPercentage: string;
  tenantId: string;
};

export type RegistryKeyValueType =
  | "Binary"
  | "DWord"
  | "ExpandString"
  | "MultiString"
  | "None"
  | "QWord"
  | "String"
  | "Unknown";

export interface RegistryKey {
  Path: string;
  Name: string;
  Type: RegistryKeyValueType;
  Value: string | number;
}

export interface SetKeyOperation extends RegistryKey {
  Operation: "set";
}

export interface DeleteKeyOperation {
  Operation: "delete";
  Path: string;
}

export interface DeleteValueOperation {
  Operation: "delete-value";
  Path: string;
  Name: string;
}

export type RegKeyOperation =
  | SetKeyOperation
  | DeleteKeyOperation
  | DeleteValueOperation;

// 'fullPath' means if the operation includes a key value, it's name is included
// in the path (i.e. ${Path}\\${Name})
export interface RegKeyConfiguration {
  [fullPath: string]: RegKeyOperation;
}

export interface DnsResult {
  spf: 0 | 1;
  dmarc: 0 | 1;
  rms: 0 | 1;
  cname: 0 | 1;
  mx: 0 | 1;
}

export interface UserAppleCreds {
  appleId: string | null;
  appleTempPass: boolean;
}

export interface AegisUser extends UserAppleCreds {
  displayName: string;
  id: string;
  cloudId: string | null;
  active: boolean;
  email: string;
  username: string;
  trainingsComplete: UserTrainingStatus;
  latestHandbookVersion: string | null;
  lastHandbookDate: string | null;
  userType: string | null;
  checkrId: string | null;
}

export interface UserTrainingStatus {
  cui: boolean;
  dodAwareness: boolean;
  insiderThreat: boolean;
}

export interface CheckrCandidate {
  checkrId: string;
  firstName?: string;
  lastName?: string;
  email: string;
}

export interface UserCheckrStatusRow {
  backgroundCheckDate: string | null;
  backgroundCheckStatus: string | null;
  checkrId: string;
  createdAt: string;
  id: string;
  lastPulledAt: string | null;
  userId: string;
}

export interface AzureUserInfo {
  userPrincipalName: string;
  assignedLicenses: string[];
  licenseCount: number | null;
  usageTime: number | null;
}

export interface AtomusGroupsStatuses {
  adminGroupId: string | null;
  readerGroupId: string | null;
  dashboardGroupId: string | null;
}

export interface GroupRoles {
  armRoles: string[];
  aadRoles: string[];
}

export interface SharePointSite {
  name: string;
  url: string;
}

export interface VpnSslResponse {
  vals?: string;
  sslExpired: boolean;
}

export interface ProfileRequestBody {
  description: string;
  displayName: string;
  payloadName: string;
  payloadFileName: string;
  payloadFileUrl?: string;
  payload?: string;
  "@odata.type"?: string;
}

export interface AegisAppConfig {
  OptionalFeatureSubscriptions?: Partial<Record<OptionalFeatureId, unknown>>;
  [key: string]: any;
}

export interface ArcExpirationReponseBody {
  expirationDate: string;
  isExpired: boolean;
}

export interface SubscriptionIdResponseBody {
  subscriptionId: string;
}

export type DefenderEnrollmentStatuses =
  | {
      active: true;
      macOSSuccess: number;
      macOSTotal: number;
      windowsSuccess: number;
      windowsTotal: number;
    }
  | { active: false };

export interface CompanyMsftInfo {
  id: string;
  name: string;
  tenantId: string | null;
  subscriptionId: string | null;
  cloudInstance: string | null;
}

/**
 * @description an Atomus tenant from the db
 */
export interface AtomusTenant {
  name: string;
  shortform: string;
  tenantId: string;
  domain?: string;
  compliantDomain: string | null;
  documentationPage: string | null;
  subscriptionId: string;
  cloudInstance: "commercial" | "gcc" | "gccHigh";
}

export interface TenantListResponse {
  // tenants that exist in the db and the user is able to access using microsoft api
  mappedTenants: AtomusTenant[];
  // tenants in the db that the user's account does not have access to
  noMsftAccessTenants: AtomusTenant[];
  // tenants that exist in the db, and the user has access to, but needs to get
  // a new token with mfa
  authRequiredTenants: AtomusTenant[];
  // tenants that returned an error (other than the mfa error) when making calls to the
  // tenant with the graph api
  errorTenants: (Exclude<AtomusTenant, "tenantName"> & { error: string })[];
  // companies in the db that are missing msft column info
  needMsftInfo: CompanyMsftInfo[];
}

export interface IntuneUploadResponse {
  result: "created" | "updated" | "error";
}

// Picked fields from db device row fields for user onboarding email devices
export interface DbDeviceRowFields {
  id: string;
  os: string;
  status: DeviceOnboardingStatus;
}

export const DeviceOSValues = {
  Mac: "MacOS",
  Windows: "Windows",
  Linux: "Linux",
  Android: "Android",
  iOS: "iOS",
};

export type DeviceOS = keyof typeof DeviceOSValues;

export interface AccountSetupUser {
  userType: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  msftTempPass: string;
  phone: string;
  numberOfDevices: number;
  devices: DeviceOS[];
}

export interface ExternalAzureUser {
  displayName: string;
  userPrincipalName: string;
}

export interface ExternalAzureUserWithId extends ExternalAzureUser {
  id: string;
}

export interface GetExternalUsersResponse {
  atomusUsers: ExternalAzureUserWithId[];
  otherUsers: ExternalAzureUserWithId[];
}

export interface ExternalUserInvitationReponse {
  inviteRedeemUrl: string;
  invitedUserDisplayName: string;
  invitedUserEmailAddress: string;
}

export interface AccountInfo {
  // name of the company. Used in user creation
  companyName: string;
  // name of the account for naming resources. For example, the company's resource
  // group would have the name "accountName-rg".
  accountName: string;
  // where to deploy resources
  location: string;
  // the company's domain. Emails will have the form "userName@atomuscyber.com"
  companyDomain: string;
}

/**
 * @description statuses that can be returned when getting a step
 */
export type GetStepResult = "complete" | "error" | "incomplete";

/**
 * @description statuses an account setup execute step can end with
 */
export type StepResult = "complete" | "error" | "exists" | "skipped";

/**
 * @description log the status of steps in the account setup process.
 */
export type SetupLog = Partial<
  Record<string, { result: StepResult; trace: string[] }>
>;

export interface AccountSetupInfoLog {
  message?: string;
  data?: Record<string, any>;
}

export type AccountSetupInfoLogs = Partial<
  Record<string, AccountSetupInfoLog[]>
>;

/**
 * @description returned by the AccountSetupAgent on termination of RunAccountSetup
 */
export interface AccountSetupResult {
  // whether or not all steps completed successfully
  success: boolean;
  // log of which steps were completed
  log: SetupLog;
  // contains needed resource information/log messages from steps and precheck
  info: AccountSetupInfoLogs;
  // log of errors that occured
  errors: Record<string, string>;
  // error message explaining a fatal error
  message?: string;
}

interface ConfigValueError {
  key: string;
  error: string;
}

export interface ConfigValidationError {
  message: string;
  errors: ConfigValueError[];
}

/**
 * @description log analytics manipulates the ApiActivityLog type, this type represents
 * a returned row when the table is queried.
 */
export interface ApiActivityRow {
  TenantId: string;
  SourceSystem: string;
  TimeGenerated: string;
  // annoyingly log analytics puts "global" in reqTenantId_s and tenant ids in
  // reqTenantId_g
  reqTenantId_g?: string;
  reqTenantId_s?: string;
  api_s: string;
  path_s: string;
  method_s: string;
  CorrelationId: string;
  query_s?: string;
  reqBody_s?: string;
  origin_s?: string;
  tokenTenantId_g?: string;
  displayName_s?: string;
  userPrincipalName_s?: string;
  Type: "ApiActivity_CL";
  RowNum?: number;
}

export interface UsernameRecipient {
  username: string;
}

export interface DefenderMachineRecipient {
  deviceId: string;
  deviceObjectId: string;
  deviceName: string;
  recipientEmail: string;
}

export type HealthcheckStatus = "success" | "failure" | "info";

/**
 * @description healthcheck logs for devices running aegis versions greater than
 * 6.0.0
 */
export interface HealthcheckRow {
  TenantId: string;
  SourceSystem: string;
  TimeGenerated: string;
  stepName_s: string;
  status_s: HealthcheckStatus;
  data_s: string;
  id_g: string;
  username_s: string;
  deviceName_s: string;
  platform_s: string;
  aegisVersion_s: string;
  Type: "Healthcheck_CL";
  namesArray: string[];
}

export interface BackupHealthcheckOutput {
  existingSnapshots: number;
  totalTargets: number;
  oldestSnapshot: string;
  lastMaintenanceTime: string;
  maintenanceHasRun: string | boolean;
}

export interface OnboardingsRow {
  companyId: string;
  createdAt: string;
  goalDate: string;
  id: string;
  planLink: string | null;
  updatedAt: string;
}

export type DashboardRole = "none" | "companyAdmin" | "securityAdmin";

export interface UserReadyToOnboardStatuses {
  // check that the user is active and not offline-user type
  userIsActive: string | null;
  // check that the user row has a cloud id
  userHasCloudId: string | null;
  // check that the user exists in azure
  azUserFound: string | null;
  // check that the user has microsoft license assigned
  userHasLicense: string | null;
  // check that the user is a member of the company group
  userIsInCompanyGroup: string | null;
  // check that the user's duo user exists (if duo enabled)
  duoUserExists: string | null;
  // check that the user has the app config data reader role for the app config
  userHasAppConfigReader: string | null;
  // check that the user key vault exists
  userKeyVaultExists: string | null;
  // check that the user has apple creds
  userHasAppleCreds: string | null;
  // check if Apple creds are needed for default device email (has Apple Devices in db)
  userAppleCheck: string | null;
  // check that the user has microsoft credentials
  userHasMsftCreds: string | null;
  // check that the user has devices
  userHasDevices: string | null;
}

export interface ReadyToOnboardStatuses {
  // check that the azure company group exists
  companyGroupFound: string | null;
  // check that the atomus aegis app has been granted admin consent
  aegisAppConsentGranted: string | null;
  // get all user statuses (key: userId, value: user statuses)
  userStatuses: Record<string, UserReadyToOnboardStatuses>;
}

export type AccountInfoWithOptionalLocation = Pick<
  AccountInfo,
  "accountName" | "companyDomain" | "companyName"
> & { location?: string };

export interface AccountSetupBody {
  location: string;
  users?: AccountSetupUser[];
  defenderOnboardingPayload?: string;
}

export type AccountSetupStepStatuses = [string, GetStepResult][];

export type EmailId =
  | "device-onboarding"
  | "cs-training"
  | "user-guide"
  | "employee-handbook"
  | "defender-app-recommendations"
  | "custom-device-onboarding";

export type AppRecommendationRemediationType =
  | "ConfigurationChange"
  | "Update"
  | "Upgrade"
  | "Uninstall";

export interface SecurityRecommendation {
  id?: string;
  productName?: string;
  recommendationName?: string;
  weaknesses?: number;
  vendor?: string;
  recommendedVersion?: string;
  recommendedProgram?: string;
  recommendedVendor?: string;
  recommendationCategory?:
    | "Accounts"
    | "Application"
    | "Network"
    | "OS"
    | "SecurityControls";
  subCategory?: string;
  severityScore?: number;
  publicExploit?: boolean;
  activeAlert?: boolean;
  associatedThreats?: string[];
  remediationType?: "ConfigurationChange" | "Update" | "Upgrade" | "Uninstall";
  status?: "Active" | "Exception";
  configScoreImpact?: number;
  exposureImpact?: number;
  totalMachineCount?: number;
  exposedMachinesCount?: number;
  nonProductivityImpactedAssets?: string;
  relatedComponent?: string;
}

export interface CustomDeviceOnboardingRecipient {
  username: string;
  macPartition: boolean;
  macNewDevice: boolean;
  windowsNewDevice: boolean;
  windowsPartition: boolean;
  windowsAppOnly: boolean;
  windowsSwitchUser: boolean;
  iOS: boolean;
  android: boolean;
  linux: boolean;
}

export type UsbEnabledDevicePlatform = "MacOS" | "Windows";
export type UsbApprovalStatus = "approved" | "declined" | "pending";

export interface UsbAccessRequest {
  friendlyName: string;
  serialNumbers: string[];
  platform: UsbEnabledDevicePlatform;
  requestCount: number;
  requestingMsftIds: string[];
  approvalStatus: UsbApprovalStatus;
  approvedBy: string | null;
  approvedAt: string | null;
  lastRequestedAt: string;
}

export type AtomusEmailCategory =
  | "user-guide"
  | "cs-training"
  | "employee-handbook"
  | "device-onboarding"
  | "custom-macos-new-device"
  | "custom-macos-partition"
  | "custom-windows-partition"
  | "custom-windows-switch-user"
  | "custom-windows-new-device"
  | "custom-windows-app-only"
  | "custom-ubuntu"
  | "custom-ios"
  | "custom-android"
  | "msft-password-reset"
  | "apple-password-reset"
  | "defender-app-recommendations"
  | "custom-msft-credentials"
  | "custom-apple-credentials"
  | "duo-offline-instructions";

export interface UserEmailsRequestBody {
  emailCategories: string[];
  userIdEmailMap: Record<string, string>;
}

export interface UserEmailsResponseBody {
  successes: EmailSuccesses;
  errors: EmailErrors;
}

export type EmailErrors = Record<
  string,
  Record<AtomusEmailCategory | "misc" | "custom", string[]>
>;
type EmailSuccesses = Record<string, AtomusEmailCategory[]>;

export interface EmailTimelineEntry {
  friendlyName: string;
  emailCategory: AtomusEmailCategory;
  createdAt: string;
  sentTo: string;
}
// key = user id, value = array of email timeline entries associated with that user
export type EmailTimelines = Record<string, EmailTimelineEntry[]>;

// request type for the SMS activation request
export interface DuoSmsActivationRequestBody {
  userId: string;
  phone: string;
  email: string;
}
