/**
 * This component is for scrollable content that must extent to the bottom of the screen.
 */

import { Box, BoxProps } from "@chakra-ui/react";
import { ReactNode, useEffect, useRef, useState } from "react";

interface LastScrollableContainerProps extends BoxProps {
  children: ReactNode;
  maxHeight?: string;
}

export default function LastScrollableContainer({
  children,
  ...props
}: Readonly<LastScrollableContainerProps>) {
  const [containerHeight, setContainerHeight] = useState("100%");
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const calculateHeight = () => {
      const rect = containerRef.current?.getBoundingClientRect();
      if (rect) {
        const remainingHeight = window.innerHeight - rect.top - 16;
        setContainerHeight(`${remainingHeight}px`);
      }
    };

    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    return () => window.removeEventListener("resize", calculateHeight);
  }, []);

  return (
    <Box
      overflowY="auto"
      maxHeight={containerHeight}
      {...props}
      ref={containerRef}
    >
      {children}
    </Box>
  );
}
