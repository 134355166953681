import {
  Flex,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect } from "react";
import SortableTh from "../../../../Components/SortableTh/SortableTh";
import TableSkeleton from "../../../../Components/TableSkeleton/TableSkeleton";
import { INTUNE_MDM_APP_ID } from "../../../../constants/microsoft.constants";
import { useIncorrectlyEnrolledDevices } from "../../../../hooks/devices.hooks";
import { useDataSorting } from "../../../../hooks/utils.hooks";
import { Device } from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { ConverterMap } from "../../../../types/utils.types";
import { isExpectedTrustType } from "../../../../utils/devices.utils";
import TableRow from "./TableRow";
import LastScrollableContainer from "../../../../Components/LastScrollableContainer";

const CONVERTERS: ConverterMap<Device> = {
  registeredUsers: (device) =>
    device.registeredUsers?.map((user) => user.userPrincipalName).join(", ") ??
    "",
  trustType: (device) => {
    if (isExpectedTrustType(device)) {
      return "yes";
    }
    return "no";
  },
  mdmAppId: (device) => (device.mdmAppId === INTUNE_MDM_APP_ID ? "yes" : "no"),
} as const;

export default function DevicesToReviewTable({
  tenantId,
}: Readonly<{
  tenantId: string;
}>) {
  const { error, data } = useIncorrectlyEnrolledDevices(tenantId);
  const { activeItems, handleSort, sortDir, sortKey } = useDataSorting(
    data,
    CONVERTERS
  );

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return (
    <Flex flexDir="column" gap="12px">
      <Heading>Devices to review</Heading>
      <Text>
        The devices below contains Aegis devices that are incorrectly enrolled
        in your organization's MDM.
      </Text>
      <TableContainer>
        <LastScrollableContainer>
          <Table>
            <Thead position="sticky" top="0" zIndex="1" bg="white">
              <Tr>
                <SortableTh
                  isSorted={sortKey === "displayName"}
                  sortDir={sortDir}
                  sortFunc={() => handleSort("displayName")}
                >
                  Device name
                </SortableTh>
                <SortableTh
                  isSorted={sortKey === "registeredUsers"}
                  sortDir={sortDir}
                  sortFunc={() => handleSort("registeredUsers")}
                >
                  Registered user(s)
                </SortableTh>
                <SortableTh
                  isSorted={sortKey === "operatingSystem"}
                  sortDir={sortDir}
                  sortFunc={() => handleSort("operatingSystem")}
                >
                  Operating system
                </SortableTh>
                <SortableTh
                  isSorted={sortKey === "trustType"}
                  sortDir={sortDir}
                  sortFunc={() => handleSort("trustType")}
                >
                  Entra AD Joined
                </SortableTh>
                <SortableTh
                  isSorted={sortKey === "mdmAppId"}
                  sortDir={sortDir}
                  sortFunc={() => handleSort("mdmAppId")}
                >
                  Connected to Intune
                </SortableTh>
                <SortableTh
                  isSorted={sortKey === "enrollmentType"}
                  sortDir={sortDir}
                  sortFunc={() => handleSort("enrollmentType")}
                >
                  Intune Enrollment Type
                </SortableTh>
              </Tr>
            </Thead>
            <Tbody>
              {!error && activeItems.length !== data?.length && (
                <TableSkeleton numCols={6} />
              )}
              {activeItems.length > 0 &&
                activeItems.map((device) => (
                  <TableRow key={device.id} device={device} />
                ))}
            </Tbody>
            {data?.length === 0 && (
              <TableCaption>No devices to review</TableCaption>
            )}
            {error && (
              <TableCaption color="red">Error loading devices</TableCaption>
            )}
          </Table>
        </LastScrollableContainer>
      </TableContainer>
    </Flex>
  );
}
