import {
  Button,
  Center,
  Flex,
  Icon,
  Progress,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { BsArrowRepeat } from "react-icons/bs";
import AuthContext from "../../../contexts/AuthContext";
import { useDevices } from "../../../hooks/devices.hooks";
import useMicrosoftToken from "../../../hooks/tokens.hooks";
import { useTracking } from "../../../hooks/tracking.hooks";
import { toastError, toastSuccess } from "../../../Providers/ToastProvider";
import { triggerIntuneSync } from "../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { navigateToAdminConsent } from "../../../utils/utils";
import DeviceDetailsModal from "./DeviceDetailsModal";
import DevicesTable from "./DevicesTable";
import DevicesToReviewTable from "./UnenrolledDevicesTable/DevicesToReviewTable";
import { DevicesSectionContext } from "./DevicesSectionContext";
import { createQueryClient } from "../../../utils/sections.utils";
import { QueryClientProvider } from "react-query";

interface IDevicesSectionProps {
  tenantId: string;
}

const devicesQueryClient = createQueryClient();
export default function DevicesSection({
  tenantId,
}: Readonly<IDevicesSectionProps>) {
  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure();
  const [modalDeviceId, setModalDeviceId] = useState<string | null>(null);

  const { isLoading, isRefetching, isError, error, data, refetch } =
    useDevices(tenantId);
  const {
    atomusDevices,
    mobileDevices,
    disabledDevices,
    nonAtomusDevices,
    devices,
    setDevices,
  } = useContext(DevicesSectionContext);
  const [syncIsLoading, setSyncIsLoading] = useState<boolean>(false);
  const { trackTabClick } = useTracking();
  const toastProvider = useToast();
  const { getInternalApiToken } = useMicrosoftToken();
  const { role, isGov } = useContext(AuthContext);

  useEffect(() => {
    setDevices(data ?? null);
  }, [data, setDevices]);

  const handleTriggerIntuneSync = async () => {
    try {
      const token = await getInternalApiToken();
      setSyncIsLoading(true);
      await triggerIntuneSync(token, tenantId);
      setSyncIsLoading(false);
      toastSuccess(toastProvider, "Sync initated");
    } catch (error) {
      if ((error as Error).message.includes("not authorized")) {
        navigateToAdminConsent(tenantId, isGov, "webApp");
        toastError(toastProvider, "Admin consent to application required");
        setSyncIsLoading(false);
      }
      toastError(toastProvider, "Failed to trigger Intune sync");
      setSyncIsLoading(false);
    }
  };

  if (isLoading || isRefetching) {
    return <Progress isIndeterminate borderRadius="md" />;
  }

  if (isError) {
    console.error(error.message);
    return (
      <Center>
        <Flex flexDir="column" alignItems="center">
          <Tag width="60px" colorScheme="red" padding="10px" size="lg" m="10px">
            Error
          </Tag>
          <Button
            onClick={() => {
              refetch({ throwOnError: true });
            }}
            colorScheme="blue"
            variant="ghost"
          >
            Refresh
          </Button>
        </Flex>
      </Center>
    );
  }

  const refetchData = () => {
    devicesQueryClient.refetchQueries();
    refetch();
  };

  return (
    <QueryClientProvider client={devicesQueryClient}>
      <DeviceDetailsModal
        isOpen={modalIsOpen}
        onOpen={modalOnOpen}
        onClose={modalOnClose}
        deviceId={modalDeviceId}
        setModalDeviceId={setModalDeviceId}
        tenantId={tenantId}
      />

      {devices && (
        <Tabs variant="enclosed" colorScheme="blue" isLazy>
          <TabList as={Flex} justifyContent="space-between">
            <Tab onClick={() => trackTabClick("Devices", "Atomus devices")}>
              Atomus devices
            </Tab>
            <Tab onClick={() => trackTabClick("Devices", "Non-Atomus devices")}>
              Non-Atomus devices
            </Tab>
            <Tab onClick={() => trackTabClick("Devices", "Disabled devices")}>
              Disabled devices
            </Tab>
            <Tab onClick={() => trackTabClick("Devices", "Mobile devices")}>
              Mobile devices
            </Tab>
            <Tab onClick={() => trackTabClick("Devices", "Devices to review")}>
              Devices to review
            </Tab>
            <Spacer />
            {role === "securityAdmin" && (
              <Flex alignContent="right" mr="20px">
                <Button
                  size="sm"
                  colorScheme="blue"
                  variant="ghost"
                  marginRight={4}
                  onClick={refetchData}
                >
                  Refresh
                </Button>
                <Tooltip label="Attempt to make a connection with each domain joined device to apply organization configurations and policies">
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={handleTriggerIntuneSync}
                    isLoading={syncIsLoading}
                  >
                    <Icon as={BsArrowRepeat} mr="8px" />
                    Trigger Intune Sync
                  </Button>
                </Tooltip>
              </Flex>
            )}
          </TabList>

          <TabPanels>
            <TabPanel>
              {/* Atomus Devices */}
              <DevicesTable
                tenantId={tenantId}
                devices={atomusDevices}
                modalOnOpen={modalOnOpen}
                setModalDeviceId={setModalDeviceId}
              />
            </TabPanel>
            <TabPanel>
              {/* Non-Atomus Devices */}
              <DevicesTable
                tenantId={tenantId}
                devices={nonAtomusDevices}
                modalOnOpen={modalOnOpen}
                setModalDeviceId={setModalDeviceId}
              />
            </TabPanel>
            <TabPanel>
              {/* Disabled Devices */}
              <DevicesTable
                tenantId={tenantId}
                devices={disabledDevices}
                modalOnOpen={modalOnOpen}
                setModalDeviceId={setModalDeviceId}
              />
            </TabPanel>
            <TabPanel>
              {/* Mobile Devices */}
              <DevicesTable
                tenantId={tenantId}
                devices={mobileDevices}
                modalOnOpen={modalOnOpen}
                setModalDeviceId={setModalDeviceId}
                mobileSection={true}
              />
            </TabPanel>
            <TabPanel>
              <DevicesToReviewTable tenantId={tenantId} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </QueryClientProvider>
  );
}
