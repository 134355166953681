import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Text,
} from "@chakra-ui/react";
import { useContext } from "react";
import { GroupMgmtContext } from "../GroupMgmtContext";
import { useAtomusGroupsStatus } from "../../../../../hooks/groups.hooks";
import { ExternalAzureUserWithId } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";

export default function UnassignedUserSection({
  tenantId,
  users,
}: Readonly<{ tenantId: string; users: ExternalAzureUserWithId[] }>) {
  const { handleAddUserToGroup, buttonsLoading } = useContext(GroupMgmtContext);

  const { data } = useAtomusGroupsStatus(tenantId);

  return (
    <Box
      overflowY="scroll"
      width="100%"
      minHeight="30px"
      maxHeight="200px"
      overflow="scroll"
      border="2px solid"
      borderColor="blue.500"
      p="5px"
      rounded="7px"
    >
      <Table
        variant="simple"
        marginBottom={4}
        width="100%"
        __css={{ "table-layout": "fixed" }}
      >
        <Thead>
          <Tr>
            <Th width="40%">Name</Th>
            <Th width="40%">UPN</Th>
            <Th width="20%"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((user) => (
            <Tr key={user.id}>
              <Td>{user.displayName}</Td>
              <Td>
                <Box maxWidth="100%" overflow="hidden" textOverflow="ellipsis">
                  <Text as="span" whiteSpace="nowrap">
                    {user.userPrincipalName}
                  </Text>
                </Box>
              </Td>
              <Td>
                <Menu>
                  <MenuButton
                    as={Button}
                    width={20}
                    colorScheme="blue"
                    variant="ghost"
                    size="sm"
                    isLoading={buttonsLoading}
                    disabled={!data?.adminGroupId || buttonsLoading}
                  >
                    Assign
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      isDisabled={buttonsLoading}
                      onClick={() =>
                        handleAddUserToGroup(
                          tenantId,
                          data?.adminGroupId!,
                          "admin",
                          user
                        )
                      }
                    >
                      Add to [Atomus] Admins Group
                    </MenuItem>
                    {data?.readerGroupId !== null && (
                      <MenuItem
                        isDisabled={buttonsLoading}
                        onClick={() =>
                          handleAddUserToGroup(
                            tenantId,
                            data?.readerGroupId!,
                            "reader",
                            user
                          )
                        }
                      >
                        Add to [Atomus] Read-Only Admins Group
                      </MenuItem>
                    )}
                    {data?.dashboardGroupId !== null && (
                      <MenuItem
                        isDisabled={buttonsLoading}
                        onClick={() =>
                          handleAddUserToGroup(
                            tenantId,
                            data?.dashboardGroupId!,
                            "dashboardAccess",
                            user
                          )
                        }
                      >
                        Add to [Atomus] Security Dashboard Access Admins Group
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
