import { createContext, ReactNode, useState, useMemo } from "react";
import {
  AegisUser,
  AtomusEmailCategory,
  CheckrCandidate,
  Device,
} from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { StateSetter } from "../../../../types/types";

export interface IUserTableContext {
  selectedEmails: AtomusEmailCategory[];
  setSelectedEmails: StateSetter<AtomusEmailCategory[]>;
  // ids of users who have blocking emails
  userBlockedEmails: string[];
  setUserBlockedEmails: StateSetter<string[]>;
  handleEmailSelectCheckbox: (emailCategory: AtomusEmailCategory) => void;
  selectedUserIds: Record<string, string>; // key = userId, value = email to send to
  setSelectedUserIds: StateSetter<Record<string, string>>;
  usersArr: AegisUser[];
  setUsersArr: StateSetter<AegisUser[]>;
  checkrCandidates: CheckrCandidate[];
  setCheckrCandidates: StateSetter<CheckrCandidate[]>;
  checkrCandidatesIsLoading: boolean;
  setCheckrCandidatesIsLoading: StateSetter<boolean>;
  checkrConnectionStatus: boolean;
  setCheckrConnectionStatus: StateSetter<boolean>;
  showActiveUsers: boolean;
  setShowActiveUsers: StateSetter<boolean>;
  showInactiveUsers: boolean;
  setShowInactiveUsers: StateSetter<boolean>;
  showOfflineUsers: boolean;
  setShowOfflineUsers: StateSetter<boolean>;
  userDeviceMap: Record<string, Device[]>;
  setUserDeviceMap: (map: Record<string, Device[]>) => void;
}

const defaultError = () => {
  throw new Error("you must wrap your component in an UserTableContext");
};

const defaultValue: IUserTableContext = {
  selectedEmails: [],
  setSelectedEmails: defaultError,
  userBlockedEmails: [],
  setUserBlockedEmails: defaultError,
  handleEmailSelectCheckbox: defaultError,
  selectedUserIds: {},
  setSelectedUserIds: defaultError,
  usersArr: [],
  setUsersArr: defaultError,
  checkrCandidates: [],
  setCheckrCandidates: defaultError,
  checkrCandidatesIsLoading: false,
  setCheckrCandidatesIsLoading: defaultError,
  checkrConnectionStatus: false,
  setCheckrConnectionStatus: defaultError,
  showActiveUsers: false,
  setShowActiveUsers: defaultError,
  showInactiveUsers: false,
  setShowInactiveUsers: defaultError,
  showOfflineUsers: false,
  setShowOfflineUsers: defaultError,
  userDeviceMap: {},
  setUserDeviceMap: () => {},
};

export const UserTableContext = createContext<IUserTableContext>(defaultValue);

export function UserTableContextProvider({
  children,
}: Readonly<{
  children?: ReactNode;
}>) {
  const [selectedUserIds, setSelectedUserIds] = useState<
    Record<string, string>
  >({});
  const [userBlockedEmails, setUserBlockedEmails] = useState<string[]>([]);
  const [usersArr, setUsersArr] = useState<AegisUser[]>([]);
  const [checkrCandidates, setCheckrCandidates] = useState<CheckrCandidate[]>(
    []
  );
  const [checkrCandidatesIsLoading, setCheckrCandidatesIsLoading] =
    useState<boolean>(false);
  const [checkrConnectionStatus, setCheckrConnectionStatus] =
    useState<boolean>(false);
  const [selectedEmails, setSelectedEmails] = useState<AtomusEmailCategory[]>(
    []
  );
  const [showActiveUsers, setShowActiveUsers] = useState(true);
  const [showInactiveUsers, setShowInactiveUsers] = useState(false);
  const [showOfflineUsers, setShowOfflineUsers] = useState(false);
  const [userDeviceMap, setUserDeviceMap] = useState<Record<string, Device[]>>(
    {}
  );

  const handleEmailSelectCheckbox = (emailCategory: AtomusEmailCategory) => {
    setSelectedEmails(
      (prevSelected) =>
        prevSelected.includes(emailCategory)
          ? prevSelected.filter((email) => email !== emailCategory) // Uncheck: remove from array
          : [...prevSelected, emailCategory] // Check: add to array
    );
  };

  const contextValue = useMemo(() => {
    return {
      selectedEmails,
      setSelectedEmails,
      userBlockedEmails,
      setUserBlockedEmails,
      handleEmailSelectCheckbox,
      selectedUserIds,
      setSelectedUserIds,
      usersArr,
      setUsersArr,
      checkrCandidates,
      setCheckrCandidates,
      checkrCandidatesIsLoading,
      setCheckrCandidatesIsLoading,
      checkrConnectionStatus,
      setCheckrConnectionStatus,
      showActiveUsers,
      setShowActiveUsers,
      showInactiveUsers,
      setShowInactiveUsers,
      showOfflineUsers,
      setShowOfflineUsers,
      userDeviceMap,
      setUserDeviceMap,
    };
  }, [
    selectedEmails,
    setSelectedEmails,
    userBlockedEmails,
    setUserBlockedEmails,
    selectedUserIds,
    setSelectedUserIds,
    usersArr,
    setUsersArr,
    checkrCandidates,
    setCheckrCandidates,
    checkrCandidatesIsLoading,
    setCheckrCandidatesIsLoading,
    checkrConnectionStatus,
    setCheckrConnectionStatus,
    showActiveUsers,
    setShowActiveUsers,
    showInactiveUsers,
    setShowInactiveUsers,
    showOfflineUsers,
    setShowOfflineUsers,
    userDeviceMap,
  ]);

  return (
    <UserTableContext.Provider value={contextValue}>
      {children}
    </UserTableContext.Provider>
  );
}
