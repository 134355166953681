import { Button, Flex, useToast } from "@chakra-ui/react";
import UsbsTable from "./UsbsTable";
import { useUsbRequests } from "../../../hooks/usbs.hooks";
import LoadingBar from "../../../Components/LoadingBar";
import { useEffect, useState } from "react";
import { UsbAccessRequest } from "../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { toastError } from "../../../Providers/ToastProvider";
import LastScrollableContainer from "../../../Components/LastScrollableContainer";

interface ISecuritySectionProps {
  tenantId: string;
}

export default function UsbsSection({
  tenantId,
}: Readonly<ISecuritySectionProps>) {
  const { data, isLoading, refetch, isRefetching } = useUsbRequests(tenantId);
  const [macUsbRequests, setMacUsbRequests] = useState<UsbAccessRequest[]>([]);
  const [windowsUsbRequests, setWindowsUsbRequests] = useState<
    UsbAccessRequest[]
  >([]);
  const toastProvider = useToast();

  useEffect(() => {
    const macRequests = [];
    const windowsRequests = [];
    if (data) {
      for (const usbRequest of data) {
        if (usbRequest.platform === "MacOS") {
          macRequests.push(usbRequest);
        } else if (usbRequest.platform === "Windows") {
          windowsRequests.push(usbRequest);
        }
      }
    }
    setMacUsbRequests(macRequests);
    setWindowsUsbRequests(windowsRequests);
  }, [data, setMacUsbRequests, setWindowsUsbRequests]);

  return (
    <LastScrollableContainer>
      {isLoading || isRefetching ? (
        <LoadingBar />
      ) : (
        <>
          <UsbsTable
            tenantId={tenantId}
            platform="MacOS"
            requests={macUsbRequests}
          />
          <UsbsTable
            tenantId={tenantId}
            platform="Windows"
            requests={windowsUsbRequests}
          />

          <Flex flexDir="row" justifyContent="center">
            <Button
              size="sm"
              m="10px"
              variant="ghost"
              colorScheme="blue"
              onClick={async () => {
                try {
                  await refetch({ throwOnError: true });
                } catch (error) {
                  toastError(toastProvider, "Error fetching usb requests.");
                }
              }}
            >
              Refresh
            </Button>
          </Flex>
        </>
      )}
    </LastScrollableContainer>
  );
}
