import {
  Box,
  Flex,
  HStack,
  Skeleton,
  Stat,
  StatLabel,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";
import useMicrosoftToken from "../../../../hooks/tokens.hooks";
import { SupportedPlatforms } from "../../../../services/atomus-internal-apis/atomus-internal-apis.constants";
import { getIntuneAppStatus } from "../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  AppInfoResponse,
  SupportedPlatform,
} from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { isSuccessAegisResponse } from "../../../../utils/utils";

interface IPlatformOverviewStatusProps {
  platform: Exclude<SupportedPlatform, "all">;
  releaseAppInfoQuery: UseQueryResult<AppInfoResponse, Error>;
  tenantAppInfoQuery: UseQueryResult<AppInfoResponse, Error>;
}

export default function PlatformStatus({
  releaseAppInfoQuery,
  tenantAppInfoQuery,
  platform,
}: IPlatformOverviewStatusProps) {
  const platformDisplayName = getPlatformDisplayName(platform);
  const tenantId = useParams().tenantId as string;
  const { getInternalApiToken } = useMicrosoftToken();

  const [releaseVersion, setReleaseVersion] = useState<string>("");
  const [releaseVersionColor, setReleaseVersionColor] =
    useState<string>("gray");

  const [tenantVersion, setTenantVersion] = useState<string>("");
  const [tenantVersionColor, setTenantVersionColor] = useState<string>("gray");

  const [stagingPercentage, setStagingPercentage] = useState<string>("");

  const intuneQuery = useQuery<boolean, Error>(
    [tenantId, "intune-app", platform],
    async () => {
      if (platform !== SupportedPlatforms.WIN) {
        return false;
      }
      const token = await getInternalApiToken();
      return getIntuneAppStatus(token, tenantId, platform);
    }
  );

  useEffect(() => {
    const data = releaseAppInfoQuery.data;
    if (data) {
      const versionData = data[platform];
      if (versionData && isSuccessAegisResponse(versionData)) {
        setReleaseVersion(versionData.data.version);
        setReleaseVersionColor("gray");
      } else {
        setReleaseVersion("unknown");
        setReleaseVersionColor("red");
      }
    }
  }, [releaseAppInfoQuery.data, platform]);

  useEffect(() => {
    const data = tenantAppInfoQuery.data;
    if (data) {
      const versionData = data[platform];
      if (versionData && isSuccessAegisResponse(versionData)) {
        setTenantVersion(versionData.data.version);
        setStagingPercentage(
          versionData.data.stagingPercentage?.toString() || "unknown"
        );
        if (releaseVersion && releaseVersion === versionData.data.version) {
          setTenantVersionColor("green");
        } else if (releaseVersion && releaseVersion !== "unknown") {
          setTenantVersionColor("orange");
        } else {
          setTenantVersionColor("gray");
        }
      } else {
        setTenantVersion("unknown");
        setTenantVersionColor("red");
        setStagingPercentage("unknown");
      }
    }
  }, [tenantAppInfoQuery.data, platform, releaseVersion]);

  return (
    <Box marginY={4}>
      <Flex alignItems="baseline" marginY={4}>
        <Text fontSize="lg" fontWeight="semibold">
          {platformDisplayName}
        </Text>
      </Flex>
      <HStack marginX={4} alignItems="flex-start">
        <Stat>
          <StatLabel>Release Version</StatLabel>
          <Skeleton
            isLoaded={
              !releaseAppInfoQuery.isLoading &&
              !releaseAppInfoQuery.isRefetching &&
              releaseVersion !== ""
            }
          >
            <Tag colorScheme={releaseVersionColor}>{releaseVersion}</Tag>
          </Skeleton>
        </Stat>
        <Stat>
          <StatLabel>Tenant Version</StatLabel>
          <Skeleton
            isLoaded={
              !tenantAppInfoQuery.isLoading &&
              !tenantAppInfoQuery.isRefetching &&
              tenantVersion !== ""
            }
          >
            <Tag colorScheme={tenantVersionColor}>{tenantVersion}</Tag>
          </Skeleton>
        </Stat>
        <Stat>
          <StatLabel>Staging Percentage</StatLabel>
          <Skeleton
            isLoaded={
              !tenantAppInfoQuery.isLoading &&
              !tenantAppInfoQuery.isRefetching &&
              stagingPercentage !== ""
            }
          >
            {stagingPercentage === "unknown" ? (
              <Tag colorScheme="red">{stagingPercentage}</Tag>
            ) : (
              <Tag>{stagingPercentage}%</Tag>
            )}
          </Skeleton>
        </Stat>
        {platform === SupportedPlatforms.WIN && (
          <Stat>
            <StatLabel>Intune</StatLabel>
            <Skeleton
              isLoaded={!intuneQuery.isLoading && !intuneQuery.isRefetching}
            >
              <Tag colorScheme={intuneQuery.data === true ? "green" : "red"}>
                {intuneQuery.isError
                  ? intuneQuery.error.message
                  : `${intuneQuery.data}` || `${false}`}
              </Tag>
            </Skeleton>
          </Stat>
        )}
      </HStack>
    </Box>
  );
}

function getPlatformDisplayName(platform: SupportedPlatform) {
  switch (platform) {
    case SupportedPlatforms.MAC:
      return "Mac";
    case SupportedPlatforms.WIN:
      return "Windows";
    case SupportedPlatforms.LINUX:
      return "Linux";
    default:
      throw new Error(
        `Platform ${platform} is not supported for this component`
      );
  }
}
