import {
  createContext,
  ReactNode,
  useState,
  useMemo,
  useCallback,
} from "react";
import { ExternalAzureUserWithId } from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { StateSetter } from "../../../../types/types";
import useMicrosoftToken from "../../../../hooks/tokens.hooks";
import { addGroupUser } from "../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { useToast } from "@chakra-ui/react";
import { toastError } from "../../../../Providers/ToastProvider";

export interface IGroupMgmtContext {
  adminGroupUsers: ExternalAzureUserWithId[];
  setAdminGroupUsers: StateSetter<ExternalAzureUserWithId[]>;
  readerGroupUsers: ExternalAzureUserWithId[];
  setReaderGroupUsers: StateSetter<ExternalAzureUserWithId[]>;
  dashboardGroupUsers: ExternalAzureUserWithId[];
  setDashboardGroupUsers: StateSetter<ExternalAzureUserWithId[]>;
  unassignedAtomusUsers: ExternalAzureUserWithId[];
  setUnassignedAtomusUsers: StateSetter<ExternalAzureUserWithId[]>;
  unassignedOtherUsers: ExternalAzureUserWithId[];
  setUnassignedOtherUsers: StateSetter<ExternalAzureUserWithId[]>;
  buttonsLoading: boolean;
  setButtonsLoading: StateSetter<boolean>;
  handleAddUserToGroup: (
    tenantId: string,
    groupId: string,
    group: "admin" | "reader" | "dashboardAccess",
    user: ExternalAzureUserWithId
  ) => void;
}

const defaultError = () => {
  throw new Error(
    "you must wrap your component in an GroupManagementContextProvider"
  );
};

const defaultValue: IGroupMgmtContext = {
  handleAddUserToGroup: defaultError,
  adminGroupUsers: [],
  setAdminGroupUsers: defaultError,
  readerGroupUsers: [],
  setReaderGroupUsers: defaultError,
  dashboardGroupUsers: [],
  setDashboardGroupUsers: defaultError,
  unassignedAtomusUsers: [],
  setUnassignedAtomusUsers: defaultError,
  unassignedOtherUsers: [],
  setUnassignedOtherUsers: defaultError,
  buttonsLoading: false,
  setButtonsLoading: defaultError,
};

export const GroupMgmtContext = createContext(defaultValue);

export const GroupManagementContextProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [adminGroupUsers, setAdminGroupUsers] = useState<
    ExternalAzureUserWithId[]
  >([]);
  const [readerGroupUsers, setReaderGroupUsers] = useState<
    ExternalAzureUserWithId[]
  >([]);
  const [dashboardGroupUsers, setDashboardGroupUsers] = useState<
    ExternalAzureUserWithId[]
  >([]);
  const [unassignedAtomusUsers, setUnassignedAtomusUsers] = useState<
    ExternalAzureUserWithId[]
  >([]);
  const [unassignedOtherUsers, setUnassignedOtherUsers] = useState<
    ExternalAzureUserWithId[]
  >([]);
  const [buttonsLoading, setButtonsLoading] = useState<boolean>(false);

  const { getInternalApiToken } = useMicrosoftToken();

  const toast = useToast();

  const handleAddUserToGroup = useCallback(
    async (
      tenantId: string,
      groupId: string,
      group: "admin" | "reader" | "dashboardAccess",
      user: ExternalAzureUserWithId
    ) => {
      try {
        setButtonsLoading(true);
        const token = await getInternalApiToken();
        await addGroupUser(
          token,
          tenantId,
          groupId,
          user.id,
          group === "dashboardAccess" ? true : undefined
        );
        setUnassignedAtomusUsers((prevUsers) =>
          prevUsers.filter((unassignedUser) => unassignedUser.id !== user.id)
        );
        setUnassignedOtherUsers((prevUsers) =>
          prevUsers.filter((unassignedUser) => unassignedUser.id !== user.id)
        );
        if (group === "admin") {
          setAdminGroupUsers((prevUsers) =>
            [...prevUsers, user].sort((a, b) =>
              a.displayName > b.displayName ? 1 : -1
            )
          );
        } else if (group === "reader") {
          setReaderGroupUsers((prevUsers) =>
            [...prevUsers, user].sort((a, b) =>
              a.displayName > b.displayName ? 1 : -1
            )
          );
        } else if (group === "dashboardAccess") {
          setDashboardGroupUsers((prevUsers) =>
            [...prevUsers, user].sort((a, b) =>
              a.displayName > b.displayName ? 1 : -1
            )
          );
        }
        setButtonsLoading(false);
      } catch (err) {
        setButtonsLoading(false);
        toastError(toast, err);
      }
    },
    [getInternalApiToken, toast]
  );

  const contextValue = useMemo(() => {
    return {
      adminGroupUsers,
      setAdminGroupUsers,
      readerGroupUsers,
      setReaderGroupUsers,
      dashboardGroupUsers,
      setDashboardGroupUsers,
      unassignedAtomusUsers,
      setUnassignedAtomusUsers,
      unassignedOtherUsers,
      setUnassignedOtherUsers,
      buttonsLoading,
      setButtonsLoading,
      handleAddUserToGroup,
    };
  }, [
    adminGroupUsers,
    setAdminGroupUsers,
    readerGroupUsers,
    setReaderGroupUsers,
    dashboardGroupUsers,
    setDashboardGroupUsers,
    unassignedAtomusUsers,
    setUnassignedAtomusUsers,
    unassignedOtherUsers,
    setUnassignedOtherUsers,
    buttonsLoading,
    setButtonsLoading,
    handleAddUserToGroup,
  ]);

  return (
    <GroupMgmtContext.Provider value={contextValue}>
      {children}
    </GroupMgmtContext.Provider>
  );
};
