import { Accordion, Center, Flex, Progress, Tag } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useAtomusGroupsStatus } from "../../../../hooks/groups.hooks";
import { useExternalAtomusUsers } from "../../../../hooks/users.hooks";
import { ExternalUserInvitationReponse } from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import GroupAccordionItem from "./Components/GroupAccordionItem";
import ExternalUsersSection from "./ExternalUsersSection";
import { GroupMgmtContext } from "./GroupMgmtContext";
import GroupSection from "./GroupSection";
import UnassignedUserSection from "./UnassignedSection";
import LastScrollableContainer from "../../../../Components/LastScrollableContainer";

interface IGroupMgmtProps {
  tenantId: string;
}

export default function GroupMgmtSection({
  tenantId,
}: Readonly<IGroupMgmtProps>) {
  const { data, isFetching, isError, error } = useAtomusGroupsStatus(tenantId);
  const {
    unassignedAtomusUsers,
    setUnassignedOtherUsers,
    unassignedOtherUsers,
    setUnassignedAtomusUsers,
    adminGroupUsers,
    setAdminGroupUsers,
    readerGroupUsers,
    setReaderGroupUsers,
    dashboardGroupUsers,
    setDashboardGroupUsers,
  } = useContext(GroupMgmtContext);
  const allUsersQuery = useExternalAtomusUsers(tenantId);
  const [invitationResults, setInvitationResults] =
    useState<ExternalUserInvitationReponse[]>();

  useEffect(() => {
    if (allUsersQuery.data && allUsersQuery.isFetchedAfterMount) {
      setUnassignedAtomusUsers(allUsersQuery.data.atomusUsers);
      setUnassignedOtherUsers(allUsersQuery.data.otherUsers);
    }
  }, [
    allUsersQuery.data,
    allUsersQuery.isFetchedAfterMount,
    setUnassignedAtomusUsers,
    setUnassignedOtherUsers,
  ]);

  if (isFetching || allUsersQuery.isFetching) {
    return <Progress isIndeterminate borderRadius="md" />;
  }

  if (isError) {
    return <Center>{error.message}</Center>;
  }

  return (
    <LastScrollableContainer>
      <Flex flexDir="column">
        <Accordion allowToggle>
          <GroupAccordionItem
            title="Invitations"
            children={
              <ExternalUsersSection
                tenantId={tenantId}
                refetchFunc={allUsersQuery.refetch}
                invitationResults={invitationResults}
                setInvitationResults={setInvitationResults}
              />
            }
          />
          <GroupAccordionItem
            title="Unassigned Atomus Users"
            children={
              isFetching ? (
                <Progress isIndeterminate borderRadius="md" />
              ) : (
                <UnassignedUserSection
                  tenantId={tenantId}
                  users={unassignedAtomusUsers}
                />
              )
            }
          />
          <GroupAccordionItem
            title="Other Users"
            children={
              isFetching ? (
                <Progress isIndeterminate borderRadius="md" />
              ) : (
                <UnassignedUserSection
                  tenantId={tenantId}
                  users={unassignedOtherUsers}
                />
              )
            }
          />
          <GroupAccordionItem
            title="[Atomus] Admin Group"
            children={
              data?.adminGroupId ? (
                <GroupSection
                  tenantId={tenantId}
                  groupId={data?.adminGroupId}
                  assignedUsers={adminGroupUsers}
                  setAssignedUsers={setAdminGroupUsers}
                />
              ) : (
                <Tag colorScheme="red" width={100}>
                  Not created!
                </Tag>
              )
            }
          />
          <GroupAccordionItem
            title="[Atomus] Read-Only Admins"
            children={
              data?.readerGroupId ? (
                <GroupSection
                  tenantId={tenantId}
                  groupId={data?.readerGroupId}
                  assignedUsers={readerGroupUsers}
                  setAssignedUsers={setReaderGroupUsers}
                />
              ) : (
                <Tag colorScheme="red" width={100}>
                  Not created!
                </Tag>
              )
            }
          />
          <GroupAccordionItem
            title="[Atomus] Security Dashboard Access Admins"
            children={
              data?.dashboardGroupId ? (
                <GroupSection
                  tenantId={tenantId}
                  groupId={data?.dashboardGroupId}
                  assignedUsers={dashboardGroupUsers}
                  setAssignedUsers={setDashboardGroupUsers}
                  description="Adding/removing users here also grants/revokes access to the Dashboard (do not use Azure Portal or MSFT Admin Center)"
                />
              ) : (
                <Tag colorScheme="red" width={100}>
                  Not created!
                </Tag>
              )
            }
          />
        </Accordion>
      </Flex>
    </LastScrollableContainer>
  );
}
