import { QueryClient } from "react-query";
import { NO_REFETCH_QUERY } from "../constants/reactquery.constants";

export function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: { ...NO_REFETCH_QUERY, retry: false },
    },
  });
}
