import {
  Center,
  Progress,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Button,
  Flex,
  useDisclosure,
  Checkbox,
  Tooltip,
  Input,
  Spacer,
} from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import SortableTh from "../../../../Components/SortableTh/SortableTh";
import { useAegisUsers } from "../../../../hooks/users.hooks";
import { SortDirection } from "../../../../types/types";
import { sortStrings } from "../../../../utils/utils";
import { UserTableContext } from "./UserTableContext";
import { UserRow } from "../UserTable/UserRow/UserRow";
import {
  useCheckrCandidates,
  useCheckrConnectionStatus,
} from "../../../../hooks/integrations.hooks";
import { UserRowContextProvider } from "./UserRow/UserRowContext";
import SendEmailsModal from "./SendEmailsModal";
import UserFilterDropdown from "./Components/UserFilterDropdown";
import { UserTypes } from "../../../../services/atomus-internal-apis/atomus-internal-apis.constants";
import {
  AegisUser,
  Device,
} from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { useDevices } from "../../../../hooks/devices.hooks";
import LastScrollableContainer from "../../../../Components/LastScrollableContainer";
import DeviceDetailsModal from "../../DevicesSection/DeviceDetailsModal";

interface IUsersSectionProps {
  tenantId: string;
}

type SortKey = "displayName" | "email";

export default function UsersTable({ tenantId }: Readonly<IUsersSectionProps>) {
  const { isFetching, isError, error, data } = useAegisUsers(tenantId);
  const {
    usersArr,
    setUsersArr,
    selectedUserIds,
    setSelectedUserIds,
    setCheckrCandidates,
    setCheckrCandidatesIsLoading,
    setCheckrConnectionStatus,
    showActiveUsers,
    showInactiveUsers,
    showOfflineUsers,
    setUserDeviceMap,
  } = useContext(UserTableContext);
  const [sortDir, setSortDir] = useState<SortDirection>("asc");
  const [sortKey, setSortKey] = useState<SortKey | null>(null);
  const [searchStr, setSearchStr] = useState<string>("");

  const { data: checkrCandidatesData, isFetching: checkrCandidatesIsLoading } =
    useCheckrCandidates(tenantId);

  const { data: checkrConnectionStatus } = useCheckrConnectionStatus(tenantId);

  const sendEmailModalDisclosure = useDisclosure();

  useEffect(() => {
    if (checkrConnectionStatus) {
      setCheckrConnectionStatus(checkrConnectionStatus);
    }
  }, [setCheckrConnectionStatus, checkrConnectionStatus]);

  useEffect(() => {
    setCheckrCandidatesIsLoading(checkrCandidatesIsLoading);
    if (checkrCandidatesData) {
      setCheckrCandidates(checkrCandidatesData);
    }
  }, [
    checkrCandidatesData,
    checkrCandidatesIsLoading,
    setCheckrCandidates,
    setCheckrCandidatesIsLoading,
  ]);

  const getSortedUsers = (
    key: SortKey,
    newSortDir: "asc" | "desc",
    users: AegisUser[]
  ) => {
    const newArr = [...users].sort((a, b) =>
      sortStrings(a[key], b[key], newSortDir)
    );
    return newArr;
  };

  const setSortProperties = useCallback(
    (key: SortKey) => {
      const newSortDir = key === sortKey && sortDir === "asc" ? "desc" : "asc";
      setSortDir(newSortDir);
      setSortKey(key);
    },
    [sortDir, sortKey]
  );

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    if (!isChecked) {
      // remove user id if unchecked
      setSelectedUserIds({});
    } else {
      // create a map for each filtered user id: email
      const selectedUserMap = filteredUsers.reduce((userMap, currUser) => {
        if (currUser.id && currUser.email && currUser.active) {
          return { ...userMap, [currUser.id]: currUser.email };
        }
        return userMap;
      }, {} as Record<string, string>);
      setSelectedUserIds(selectedUserMap);
    }
  };

  const userSearchMap: Record<string, string> = useMemo(() => {
    const map: Record<string, string> = {};
    usersArr.forEach((currUser) => {
      if (currUser.id) {
        const userStr = [
          currUser.displayName,
          currUser.email,
          currUser.cloudId,
          currUser.id,
          currUser.userType,
          currUser.username,
        ]
          .join(" ")
          .toLowerCase();
        map[currUser.id] = userStr;
      }
    });
    return map;
  }, [usersArr]);

  const filteredUsers = useMemo(() => {
    const lowerSearchStr = searchStr.toLowerCase().trim();
    // get users filtered by the search bar
    const searchUsers =
      searchStr.length > 0
        ? usersArr.filter((user) =>
            userSearchMap[user.id]?.includes(lowerSearchStr)
          )
        : usersArr;
    // get users filtered by the dropdown
    const activeUsers = searchUsers.filter(
      (user) => user.active && user.userType !== UserTypes.OFFLINE
    );
    const inactiveUsers = searchUsers.filter(
      (user) => !user.active && user.userType !== UserTypes.OFFLINE
    );
    const offlineUsers = searchUsers.filter(
      (user) => user.userType === UserTypes.OFFLINE
    );
    let theseFilteredUsers: AegisUser[] = [];
    if (showActiveUsers) {
      theseFilteredUsers = [...theseFilteredUsers, ...activeUsers];
    }
    if (showInactiveUsers) {
      theseFilteredUsers = [...theseFilteredUsers, ...inactiveUsers];
    }
    if (showOfflineUsers) {
      theseFilteredUsers = [...theseFilteredUsers, ...offlineUsers];
    }
    const filteredUserIds = theseFilteredUsers.map((user) => user.id);

    // uncheck the selected users
    const filteredUserIdSelection = {} as Record<string, string>;

    setSelectedUserIds((prevSelection) => {
      for (const userId of filteredUserIds) {
        if (prevSelection[userId]) {
          filteredUserIdSelection[userId] = prevSelection[userId];
        }
      }
      return filteredUserIdSelection;
    });
    return getSortedUsers(
      sortKey ?? "displayName",
      sortDir,
      theseFilteredUsers
    );
  }, [
    searchStr,
    usersArr,
    showActiveUsers,
    showInactiveUsers,
    showOfflineUsers,
    setSelectedUserIds,
    sortKey,
    sortDir,
    userSearchMap,
  ]);

  useEffect(() => {
    if (data) {
      setUsersArr(data);
    }
  }, [data, setUsersArr]);

  // Compute a user -> device map here to pass to the row
  const devices = useDevices(tenantId);

  useEffect(() => {
    if (devices.data) {
      const deviceMap = devices.data.reduce((acc, device) => {
        device.registeredUsers?.forEach((user) => {
          if (!acc[user.id]) {
            acc[user.id] = [];
          }
          acc[user.id].push(device);
        });
        return acc;
      }, {} as Record<string, Device[]>);
      setUserDeviceMap(deviceMap);
    }
  }, [devices.data, setUserDeviceMap]);

  // For device details modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);

  if (isFetching) {
    return <Progress isIndeterminate borderRadius="md" />;
  }

  if (isError) {
    return <Center>{error.message}</Center>;
  }

  return (
    <>
      <DeviceDetailsModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        deviceId={selectedDeviceId}
        setModalDeviceId={setSelectedDeviceId}
        tenantId={tenantId}
        searchParamId="shownDeviceId"
      />

      <SendEmailsModal
        tenantId={tenantId}
        isOpen={sendEmailModalDisclosure.isOpen}
        onClose={sendEmailModalDisclosure.onClose}
      />
      <Flex flexDir="row-reverse" m="10px" alignItems="space-between">
        <Tooltip
          label={
            Object.keys(selectedUserIds).length === 0
              ? "You must select at least one user"
              : ""
          }
        >
          <Button
            m="10px"
            variant="outline"
            colorScheme="blue"
            isDisabled={Object.keys(selectedUserIds).length === 0}
            onClick={() => sendEmailModalDisclosure.onOpen()}
          >
            Send Emails
          </Button>
        </Tooltip>
        <Spacer />
        <Input
          size="sm"
          mt="10px"
          width="200px"
          placeholder="Search..."
          onChange={(e) => setSearchStr(e.target.value)}
        />
        <UserFilterDropdown />
      </Flex>
      <LastScrollableContainer>
        <Table variant="simple">
          <Thead position="sticky" top="0" zIndex="1" bg="white">
            <Tr>
              <Th>
                <Checkbox
                  onChange={handleCheckboxChange}
                  isDisabled={
                    filteredUsers.filter((user) => user.active).length === 0
                  }
                  isChecked={
                    filteredUsers
                      .filter((user) => user.active)
                      .every((user) =>
                        Object.keys(selectedUserIds).some(
                          (userId) => user.id === userId
                        )
                      ) &&
                    filteredUsers.length > 0 &&
                    Object.keys(selectedUserIds).length > 0
                  }
                />
              </Th>
              <SortableTh
                isSorted={sortKey === "displayName"}
                sortDir={sortDir}
                sortFunc={() => setSortProperties("displayName")}
              >
                Display Name
              </SortableTh>
              <SortableTh
                isSorted={sortKey === "email"}
                sortDir={sortDir}
                sortFunc={() => setSortProperties("email")}
              >
                Email
              </SortableTh>
              <Th textAlign="center">Attributes</Th>
              <Th textAlign="center">License Count</Th>
              <Th textAlign="center">Usage Time</Th>
              <Th textAlign="center">Compliance</Th>
              <Th textAlign="center">Device MFA</Th>
              <Th textAlign="center">Email Timeline</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredUsers.length > 0 &&
              filteredUsers.map((user) => (
                <UserRowContextProvider key={user.id}>
                  <UserRow
                    aegisUser={user}
                    tenantId={tenantId}
                    key={user.id}
                    deviceDetailsModalOnOpen={onOpen}
                    setModalDeviceId={setSelectedDeviceId}
                  />
                </UserRowContextProvider>
              ))}
          </Tbody>
        </Table>
      </LastScrollableContainer>
    </>
  );
}
