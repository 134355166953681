import {
  AccordionItem,
  AccordionButton,
  Flex,
  AccordionIcon,
  AccordionPanel,
  Text,
} from "@chakra-ui/react";

interface IGroupAccordionItemProps {
  title: string;
  children: React.ReactNode;
}

export default function GroupAccordionItem({
  title,
  children,
}: Readonly<IGroupAccordionItemProps>) {
  return (
    <AccordionItem>
      <AccordionButton paddingLeft="0">
        <Flex width="100%" alignItems="center">
          <Text fontSize="xl" fontWeight="semibold" mt="16px" mb="16px">
            {title}
          </Text>
        </Flex>
        <AccordionIcon></AccordionIcon>
      </AccordionButton>
      <AccordionPanel>{children}</AccordionPanel>
    </AccordionItem>
  );
}
