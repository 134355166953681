import { Flex, Td } from "@chakra-ui/react";
import UserMicrosoftOptions from "./UserAttributeOptions/UserMicrosoftOptions";
import UserDeviceOptions from "./UserAttributeOptions/UserDeviceOptions";
import UserAppleOptions from "./UserAttributeOptions/UserAppleOptions";
import { useOptionalFeatureStatus } from "../../../../../../hooks/tenants.hooks";
import UserMobileDeviceOptions from "./UserAttributeOptions/UserMobileDeviceOptions";
import { Dispatch, SetStateAction, useContext } from "react";
import AuthContext from "../../../../../../contexts/AuthContext";
import UserAssociatedDevicesOptions from "./UserAttributeOptions/UserAssociatedDevicesOptions";

type IUserAttributesSectionProps = Readonly<{
  tenantId: string;
  deviceDetailsModalOnOpen: () => void;
  setModalDeviceId: Dispatch<SetStateAction<string | null>>;
}>;

export default function UserAttributesSection({
  tenantId,
  deviceDetailsModalOnOpen,
  setModalDeviceId,
}: IUserAttributesSectionProps) {
  const { role } = useContext(AuthContext);
  const { data } = useOptionalFeatureStatus("duo:mfa", tenantId);
  const duoAvailable = !!data?.availableForTenant;

  return (
    <Td as={Flex} justify="center">
      <UserMicrosoftOptions tenantId={tenantId} />
      <UserAppleOptions tenantId={tenantId} />
      <UserDeviceOptions tenantId={tenantId} />
      <UserAssociatedDevicesOptions
        tenantId={tenantId}
        deviceDetailsModalOnOpen={deviceDetailsModalOnOpen}
        setModalDeviceId={setModalDeviceId}
      />
      {duoAvailable && role === "securityAdmin" && (
        <UserMobileDeviceOptions tenantId={tenantId} />
      )}
    </Td>
  );
}
