import axios, { AxiosResponse, AxiosError } from "axios";
import { ErrorAegisResponse, SuccessAegisResponse } from "../types/api.types";
export function sortStringArray(arr: string[]): string[] {
  return arr.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
}

export function validateAegisResponse<T>(
  res: AxiosResponse<SuccessAegisResponse<T>, any>
): T {
  if (isSuccessAegisResponse(res.data)) {
    return res.data?.data;
  } else if (isErrorAegisResponse(res.data)) {
    throw Error((res.data as ErrorAegisResponse<any>).error);
  }
  throw Error(
    "Recieved invalid response from automate: success response is not of type SuccessAegisResponse"
  );
}

export function handleApiError(error: any): never {
  if (axios.isAxiosError(error)) {
    const aegisError = (error as AxiosError<ErrorAegisResponse<any>>).response
      ?.data;
    if (isErrorAegisResponse(aegisError)) {
      throw new Error(aegisError?.error);
    }
    throw new Error(error.message);
  }
  throw error;
}

export function isSuccessAegisResponse(
  data: any
): data is SuccessAegisResponse<any> {
  return data?.isAegisResponse && data.success;
}

export function isErrorAegisResponse(
  data: any
): data is ErrorAegisResponse<any> {
  return data?.isAegisResponse && data.success === false;
}

/**
 * @description sort two strings
 * @param a the first string to compare
 * @param b the second string to compare
 * @param dir `asc` (default) to sort in ascending order `desc` to sort in descending order
 * @param numeric if true 100 > 36, if false "100" < "36"
 * @returns number representing the order of a, b
 */
export function sortStrings(
  a: string,
  b: string,
  dir: "asc" | "desc",
  numeric = true
): number {
  const mul = dir === "asc" ? 1 : -1;
  return (
    mul * a.toLowerCase().localeCompare(b.toLowerCase(), "en", { numeric })
  );
}

export function navigateToAdminConsent(
  tenantId: string,
  isGov: boolean,
  application: "aegisApp" | "webApp",
  newWindow?: true
): void {
  const redirectUri =
    window.location.hostname === "localhost"
      ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/msal-redirect`
      : `${window.location.protocol}//${window.location.hostname}/msal-redirect`;
  const webAppClientId = isGov
    ? "592d9165-5a49-4672-ab0e-170a54ab6a8f"
    : "3532456c-c2b8-493a-bda0-a39630b13b42";
  const aegisClientId = isGov
    ? "cdb93d6d-e578-4db4-aae1-713b77e7388d"
    : "aa3c215f-a451-4241-a5c5-35566194cc93";
  const searchParams = new URLSearchParams({
    client_id: application === "aegisApp" ? aegisClientId : webAppClientId,
    redirect_uri: redirectUri,
    state: `${window.location.pathname}?cloudInstance=${
      isGov ? "gov" : "commercial"
    }`,
  });
  const fullLink = isGov
    ? `https://login.microsoftonline.us/${tenantId}/adminconsent?${searchParams.toString()}`
    : `https://login.microsoftonline.com/${tenantId}/adminconsent?${searchParams.toString()}`;
  window.open(fullLink, newWindow ? `${application}_window` : "_self");
}

/**
 * @description verify if an email has a valid Atomus domain
 * @param email the email address to verify
 * @returns true if the email address is valid, otherwise false
 */
export function isAtomusEmail(email: string): boolean {
  return !!(
    email?.toLowerCase().includes("_support.atomuscyber.com#ext#") ||
    email?.toLowerCase().includes("_aegisplatform.onmicrosoft.com#ext#") ||
    email?.toLowerCase().includes("_atomuscyber.us#ext#") ||
    email?.toLowerCase().includes("_atomuscyber.com#ext#")
  );
}

/**
 * @description remove a key from an object and return all other properties
 * @param obj the object
 * @param key the key to remove
 * @returns obj with key removed
 */
export function omitPropertyFromObject<T>(obj: T, key: keyof T) {
  const { [key]: omit, ...rest } = obj;
  return rest;
}

/**
 * @description just like filter() but returns the array of non-matching results too
 * @param array array to split filter
 * @param predicate filter function to perform on each element of the array
 * @returns two arrays, the first being the filtered results, and the second being results that
 *  do not match the filter
 */
export function splitFilter<T>(
  array: T[],
  predicate: (value: T, array: T[]) => boolean
): [T[], T[]] {
  const matchArr: T[] = [];
  const unmatchArr: T[] = [];
  for (const elem of array) {
    if (predicate(elem, array)) {
      matchArr.push(elem);
    } else {
      unmatchArr.push(elem);
    }
  }
  return [matchArr, unmatchArr];
}
