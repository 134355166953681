import {
  Flex,
  Button,
  Table,
  Thead,
  Th,
  useDisclosure,
  Tbody,
  Tr,
  Td,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import useMicrosoftToken from "../../../../../hooks/tokens.hooks";
import {
  toastError,
  toastSuccess,
} from "../../../../../Providers/ToastProvider";
import { createExternalUsers } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  ExternalAzureUser,
  ExternalUserInvitationReponse,
  GetExternalUsersResponse,
} from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import ExternalUsersModal from "./AddExternalUsersModal";
import InvitedUsersInfoSection from "./InvitedUsersInfoSection";

interface IExternalUsersSectionProps {
  tenantId: string;
  refetchFunc: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<GetExternalUsersResponse, Error>>;
  invitationResults: ExternalUserInvitationReponse[] | undefined;
  setInvitationResults: React.Dispatch<
    React.SetStateAction<ExternalUserInvitationReponse[] | undefined>
  >;
}

export default function ExternalUsersSection({
  tenantId,
  refetchFunc,
  invitationResults,
  setInvitationResults,
}: Readonly<IExternalUsersSectionProps>) {
  const [externalUsers, setExternalUsers] = useState<ExternalAzureUser[]>([]);
  const [sendButtonLoading, setSendButtonLoading] = useState<boolean>(false);
  const externalUsersModalProps = useDisclosure();

  const { getInternalApiToken } = useMicrosoftToken();
  const toastProvider = useToast();

  const removeExternalUser = (idx: number) => {
    const newArr = [...externalUsers];
    newArr.splice(idx, 1);
    setExternalUsers(newArr);
  };

  const handleSendInvitations = async () => {
    setSendButtonLoading(true);
    try {
      const token = await getInternalApiToken();
      const results = await createExternalUsers(token, tenantId, externalUsers);
      setInvitationResults([...(invitationResults ?? []), ...results]);
      setExternalUsers([]);
      toastSuccess(toastProvider, "Invitations sent!");
      refetchFunc();
    } catch (err) {
      toastError(toastProvider, err);
    }
    setSendButtonLoading(false);
  };

  return (
    <>
      <ExternalUsersModal
        isOpen={externalUsersModalProps.isOpen}
        onClose={externalUsersModalProps.onClose}
        externalUsers={externalUsers}
        setExternalUsers={setExternalUsers}
      />

      {externalUsers.length > 0 ? (
        <Table marginY={2} __css={{ "table-layout": "fixed", width: "75%" }}>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {externalUsers.map((user, idx) => (
              <Tr key={user.userPrincipalName}>
                <Td>{user.displayName}</Td>
                <Td>{user.userPrincipalName}</Td>
                <Td>
                  <Button
                    size="sm"
                    colorScheme="red"
                    variant="ghost"
                    onClick={() => removeExternalUser(idx)}
                  >
                    Remove
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Divider marginY={2} />
      )}
      <Flex marginBottom={8}>
        <Button
          isDisabled={externalUsers.length === 0}
          colorScheme="blue"
          isLoading={sendButtonLoading}
          onClick={handleSendInvitations}
        >
          Send Invitations
        </Button>
        <Button
          variant="ghost"
          colorScheme="blue"
          onClick={externalUsersModalProps.onOpen}
          marginLeft={2}
        >
          Add user
        </Button>
      </Flex>
      {invitationResults && (
        <InvitedUsersInfoSection invitationResults={invitationResults} />
      )}
    </>
  );
}
