import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Icon,
  Button,
  Flex,
  Tooltip,
  Center,
  Tag,
  useDisclosure,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Spacer,
  useToast,
  Skeleton,
} from "@chakra-ui/react";
import {
  UsbAccessRequest,
  UsbApprovalStatus,
  UsbEnabledDevicePlatform,
} from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { BsPersonBadge } from "react-icons/bs";
import ConfirmationModal from "./ConfirmationModal";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../contexts/AuthContext";
import {
  triggerIntuneSync,
  updateUsbRequest,
} from "../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import useMicrosoftToken from "../../../../hooks/tokens.hooks";
import { toastError, toastSuccess } from "../../../../Providers/ToastProvider";
import { ButtonSet } from "./ApprovalButtons/Buttons";
import { useAegisUsers } from "../../../../hooks/users.hooks";
import { capitalize } from "lodash";
import ManualEntryModal from "./ManualEntryModal";

interface IUsbsTableProps {
  tenantId: string;
  platform: UsbEnabledDevicePlatform;
  requests: UsbAccessRequest[];
}

export default function UsbsTable({
  tenantId,
  platform,
  requests,
}: Readonly<IUsbsTableProps>) {
  const { msftUsername } = useContext(AuthContext);
  const { getInternalApiToken } = useMicrosoftToken();
  const {
    isOpen: confirmIsOpen,
    onClose: confirmOnClose,
    onOpen: confirmOnOpen,
  } = useDisclosure();
  const {
    isOpen: manualIsOpen,
    onClose: manualOnClose,
    onOpen: manualOnOpen,
  } = useDisclosure();
  const [manualEntryRequests, setManualEntryRequests] = useState<
    Pick<UsbAccessRequest, "serialNumbers" | "friendlyName">[]
  >([{ friendlyName: "", serialNumbers: [] }]);
  const [currentRequests, setCurrentRequests] = useState<UsbAccessRequest[]>();
  const [shownRequests, setShownRequests] = useState<UsbAccessRequest[]>();
  const [showDeclinedRequests, setShowDeclinedRequests] =
    useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<UsbAccessRequest>();
  const [approveAction, setApproveAction] = useState<boolean>(false);
  const toastProvider = useToast();
  const { isFetching, isError, data } = useAegisUsers(tenantId);

  const filterRequests = (currentRequests: UsbAccessRequest[]) => {
    return currentRequests.filter(
      (request) => request.approvalStatus !== "declined"
    );
  };

  // sort usb requests depending on their status
  const sortFunc = (a: UsbAccessRequest, b: UsbAccessRequest) => {
    const statusOrder = {
      pending: 1,
      approved: 2,
      declined: 3,
    };
    return statusOrder[a.approvalStatus] - statusOrder[b.approvalStatus];
  };

  // bring in data from query
  useEffect(() => {
    setCurrentRequests(requests.toSorted(sortFunc));
  }, [requests]);

  // set the shown requests based on the show/hide option
  useEffect(() => {
    if (currentRequests) {
      if (showDeclinedRequests) {
        setShownRequests(currentRequests.toSorted(sortFunc));
      } else {
        setShownRequests(filterRequests(currentRequests.toSorted(sortFunc)));
      }
    }
  }, [currentRequests, showDeclinedRequests]);

  // handle approval/blocks on usb requests
  const handleConfirmUpdate = async (
    requests: Pick<UsbAccessRequest, "serialNumbers" | "friendlyName">[],
    approvalStatus: "approved" | "declined"
  ) => {
    const token = await getInternalApiToken();

    const newRequests: Pick<
      UsbAccessRequest,
      | "platform"
      | "serialNumbers"
      | "approvalStatus"
      | "approvedBy"
      | "friendlyName"
    >[] = requests.map((request) => {
      return {
        platform: platform,
        serialNumbers: request.serialNumbers,
        approvalStatus,
        approvedBy: msftUsername,
        friendlyName: request.friendlyName,
      };
    });
    try {
      const updatedRequests = await updateUsbRequest(
        token,
        tenantId,
        newRequests
      );
      // async trigger an an Intune sync to push new usb configs to all devices
      triggerIntuneSync(token, tenantId).catch((_error) =>
        toastError(
          toastProvider,
          "Intune sync was unsuccessful for some devices."
        )
      );
      // update data
      if (currentRequests) {
        setCurrentRequests(
          updatedRequests.filter((request) => request.platform === platform)
        );
      }
      toastSuccess(
        toastProvider,
        `Succesfully updated USB device${
          manualEntryRequests.length > 1 ? "s" : ""
        }`
      );
      // clear out requests if succesful
      setManualEntryRequests([{ friendlyName: "", serialNumbers: [] }]);
    } catch (error) {
      toastError(
        toastProvider,
        "Update usb status failed. Please contact success@atomuscyber.com if the issue persists."
      );
    } finally {
      confirmOnClose();
    }
  };

  const getApprovalColorScheme = (status: UsbApprovalStatus) => {
    switch (status) {
      case "approved": {
        return "green";
      }
      case "declined": {
        return "red";
      }
      case "pending": {
        return "gray";
      }
    }
  };

  const buttonAction = (request: UsbAccessRequest, approve: boolean) => {
    setSelectedRequest(request);
    setApproveAction(approve);
    confirmOnOpen();
  };

  const getRequestingUsers = (msftIds: string[]): string => {
    const usersNames: string[] = [];
    if (msftIds.length === 0) {
      return "None (manual entry by admin)";
    }
    for (const id of msftIds) {
      const foundUser = data?.find((user) => id === user.cloudId);
      if (foundUser) {
        usersNames.push(foundUser.displayName);
      } else {
        usersNames.push(`unknown user: ${id}`);
      }
    }
    return usersNames.join(", ");
  };

  return (
    <Accordion allowToggle>
      <AccordionItem>
        <AccordionButton>
          <Text fontSize="lg" fontWeight="semibold">
            {platform}
          </Text>
          <Spacer />
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <Box margin={4} overflowX="scroll">
            <ManualEntryModal
              isOpen={manualIsOpen}
              onClose={manualOnClose}
              platform={platform}
              existingRequests={requests}
              manualEntryRequests={manualEntryRequests}
              setManualEntryRequests={setManualEntryRequests}
              handleConfirm={() =>
                handleConfirmUpdate(manualEntryRequests, "approved")
              }
            />
            <ConfirmationModal
              approve={approveAction}
              friendlyName={selectedRequest?.friendlyName}
              platform={platform}
              isOpen={confirmIsOpen}
              onClose={confirmOnClose}
              handleConfirm={async () => {
                if (selectedRequest)
                  await handleConfirmUpdate(
                    [selectedRequest],
                    approveAction ? "approved" : "declined"
                  );
              }}
            />
            <Button size="sm" variant="outline" onClick={manualOnOpen} m="5px">
              Manual Entry
            </Button>
            <Table variant="simple" marginTop={4}>
              <Thead>
                <Tr>
                  <Th>Device Name</Th>
                  <Th>Serial Number</Th>
                  <Th>Requesting users</Th>
                  <Th>
                    <Center>Status</Center>
                  </Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {shownRequests?.map((request) => (
                  <Tr key={request.serialNumbers.at(0)}>
                    <Td>
                      <Box width="400px">{request.friendlyName}</Box>
                    </Td>
                    <Td>
                      <Box width="200px">{request.serialNumbers.at(0)}</Box>
                    </Td>
                    <Td>
                      <Box width="100px">
                        <Center>
                          <Tooltip
                            label={
                              isFetching || isError
                                ? ""
                                : getRequestingUsers(request.requestingMsftIds)
                            }
                          >
                            <Skeleton isLoaded={!!data || isError}>
                              <Flex>
                                <Text m="4px">
                                  {request.requestingMsftIds.length}
                                </Text>
                                <Icon m="6px" as={BsPersonBadge} />
                              </Flex>
                            </Skeleton>
                          </Tooltip>
                        </Center>
                      </Box>
                    </Td>
                    <Td>
                      <Center>
                        <Tag
                          colorScheme={getApprovalColorScheme(
                            request.approvalStatus
                          )}
                        >
                          <Text>{capitalize(request.approvalStatus)}</Text>
                        </Tag>
                      </Center>
                    </Td>
                    <Td>
                      <Box width="220px">
                        <ButtonSet
                          request={request}
                          buttonAction={buttonAction}
                        />
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Center>
            <Button
              isDisabled={
                currentRequests?.length ===
                filterRequests(currentRequests ?? []).length
              }
              colorScheme="blue"
              variant="ghost"
              onClick={() => {
                setShowDeclinedRequests(!showDeclinedRequests);
              }}
            >
              {!showDeclinedRequests ? "Show" : "Hide"} declined requests
            </Button>
          </Center>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
