import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import RegKeyOperations from "../../../Components/RegKeyOperations";
import RegKeysTable from "../../../Components/RegKeysTable";
import ConfigProfileOperations from "./ConfigProfileOperations";
import LastScrollableContainer from "../../../Components/LastScrollableContainer";

interface ISecuritySectionProps {
  tenantId: string;
}

export default function SecuritySection({ tenantId }: ISecuritySectionProps) {
  return (
    <LastScrollableContainer>
      <Accordion allowToggle allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text fontSize="lg" fontWeight="semibold">
                  MacOS
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <Box margin={4}>
              <Text fontSize="lg" fontWeight="semibold">
                Configuration profiles
              </Text>
              <ConfigProfileOperations tenantId={tenantId} />
            </Box>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text fontSize="lg" fontWeight="semibold">
                  Windows
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <Box margin={4}>
              <Text fontSize="lg" fontWeight="semibold">
                Registry keys
              </Text>
              <RegKeyOperations tenantId={tenantId} />
              <RegKeysTable tenantId={tenantId} />
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </LastScrollableContainer>
  );
}
