import {
  Box,
  Button,
  Center,
  Flex,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import LoadingBar from "../../../Components/LoadingBar";
import {
  useTenantActivityLogCount,
  useTenantActivityLogs,
} from "../../../hooks/tenants.hooks";
import { ApiActivityRow } from "../../../services/atomus-internal-apis/atomus-internal-apis.types";
import ActivityEntry from "./ActivityEntry";
import ActivityModal from "./ActivityModal";
import PageSelector from "./PageSelector";
import LastScrollableContainer from "../../../Components/LastScrollableContainer";

// number of log entries to show per page
const NUM_RESULTS = 10;

interface IActivitySectionProps {
  tenantId: string;
}

export default function ActivitySection({ tenantId }: IActivitySectionProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const countQuery = useTenantActivityLogCount(tenantId);
  const logQuery = useTenantActivityLogs(tenantId, currentPage, NUM_RESULTS);
  const queriesLoading =
    countQuery.isLoading ||
    countQuery.isRefetching ||
    logQuery.isLoading ||
    logQuery.isRefetching;

  const refreshQueries = useCallback(() => {
    countQuery.refetch({ throwOnError: true });
    logQuery.refetch({ throwOnError: true });
  }, [countQuery, logQuery]);

  const modalDisclosure = useDisclosure();
  const [modalEntry, setModalEntry] = useState<ApiActivityRow | null>(null);

  return (
    <Box
      marginY={4}
      borderWidth="1px"
      borderColor="gray.400"
      borderRadius="md"
      padding={4}
    >
      <ActivityModal disclosure={modalDisclosure} entry={modalEntry} />
      <Flex alignItems="baseline">
        <Text fontSize="xl" fontWeight="semibold">
          Tenant Activity
        </Text>
        <Spacer />
        <Button
          marginY={4}
          colorScheme="blue"
          variant="ghost"
          size="sm"
          onClick={refreshQueries}
          isDisabled={queriesLoading}
        >
          Refresh
        </Button>
      </Flex>

      <LastScrollableContainer>
        {queriesLoading && (
          <Box marginY={4}>
            <LoadingBar />
          </Box>
        )}
        {countQuery.data !== undefined && countQuery.data === 0 && (
          <Text>No logs found</Text>
        )}
        {!queriesLoading && logQuery.isError && (
          <Center flexDir="column" marginY={4}>
            <Text color="red">{logQuery.error.message}</Text>
          </Center>
        )}
        {!queriesLoading &&
          logQuery.data &&
          logQuery.data.map((entry, idx) => (
            <ActivityEntry
              entry={entry}
              key={idx}
              modalOnOpen={modalDisclosure.onOpen}
              setModalEntry={setModalEntry}
            />
          ))}
        {countQuery.data !== undefined && countQuery.data > 0 && (
          <PageSelector
            logCount={countQuery.data}
            numResults={NUM_RESULTS}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            queriesLoading={queriesLoading}
          />
        )}
      </LastScrollableContainer>
    </Box>
  );
}
