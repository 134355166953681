import {
  DeviceTrustTypes,
  IntuneDeviceOs,
  IntuneEnrollmentTypes,
  INTUNE_MANAGEMENT_TYPE,
  INTUNE_MDM_APP_ID,
} from "../constants/microsoft.constants";
import { UserTypes } from "../services/atomus-internal-apis/atomus-internal-apis.constants";
import {
  AegisUser,
  Device,
} from "../services/atomus-internal-apis/atomus-internal-apis.types";

export function deviceIsEnrolledCorrectly(device: Device): boolean {
  if (
    device.operatingSystem !== IntuneDeviceOs.WINDOWS &&
    device.operatingSystem !== IntuneDeviceOs.MAC
  ) {
    throw new Error(`unsupported device OS: ${device.operatingSystem}`);
  }
  if (
    device.managementType !== "MDM" ||
    device.mdmAppId !== INTUNE_MDM_APP_ID
  ) {
    return false;
  }
  return device.operatingSystem === IntuneDeviceOs.WINDOWS
    ? device.enrollmentType === IntuneEnrollmentTypes.DOMAIN_JOINED
    : device.enrollmentType === IntuneEnrollmentTypes.USER_ENROLLMENT;
}

/**
 * @description filter devices that have a known Aegis user in the DB as a
 * registered user. Will ignore users that are inactive and `offlineUser`s
 * @param allDevices the list of all MSFT devices
 * @param aegisUsers the list of aegis users for the company
 * @returns the list of devices that have an Aegis user as a registered user
 */
export function filterAegisDevices(
  allDevices: Device[],
  aegisUsers: AegisUser[]
): Device[] {
  const usernamesMap = aegisUsers.reduce((prev, aegisUser) => {
    if (aegisUser.active && aegisUser.userType !== UserTypes.OFFLINE) {
      prev[aegisUser.username.toLowerCase()] = true;
    }
    return prev;
  }, {} as Record<string, true>);
  const aegisDevices: Device[] = [];
  for (const device of allDevices) {
    const aegisUser = device.registeredUsers?.find((user) => {
      const username = user.userPrincipalName.split("@").at(0)?.toLowerCase();
      return username && usernamesMap[username];
    });
    if (aegisUser) {
      aegisDevices.push(device);
    }
  }
  return aegisDevices;
}

/**
 * @description filter for devices that are not correctly enrolled in Intune, the
 * criteria checked (only for mac and windows):
 * 1. The management type is `MDM`
 * 2. The mdm app id is Intune's app id
 * 3. The trust type is `AzureAd` for windows devices and `AzureAd` or `Workplace` for Mac
 * devices
 * @param allDevices the list of all devices
 * @returns the devices that are not properly enrolled in Intune
 */
export function filterIncorrectlyEnrolledDevices(
  allDevices: Device[]
): Device[] {
  const filteredDevices: Device[] = [];
  const intuneOss: string[] = Object.values(IntuneDeviceOs);
  for (const device of allDevices) {
    if (!intuneOss.includes(device.operatingSystem) || !device.accountEnabled) {
      continue;
    }
    if (
      device.managementType !== INTUNE_MANAGEMENT_TYPE ||
      device.mdmAppId !== INTUNE_MDM_APP_ID
    ) {
      filteredDevices.push(device);
      continue;
    }
    let isExpectedTrustType: boolean;
    if (device.operatingSystem === IntuneDeviceOs.WINDOWS) {
      isExpectedTrustType = device.trustType === DeviceTrustTypes.AZURE_AD;
    } else {
      isExpectedTrustType =
        device.trustType === DeviceTrustTypes.AZURE_AD ||
        device.trustType === DeviceTrustTypes.WORKPLACE;
    }
    if (!isExpectedTrustType) {
      filteredDevices.push(device);
    }
  }
  return filteredDevices;
}

/**
 * @description checks if a device has the correct trust type for its os (i.e.
 * checks if the device is properly ad joined)
 * @param device the device to check
 * @returns boolean indicating if the trust type is expected
 */
export function isExpectedTrustType(device: Device): boolean {
  if (device.operatingSystem === IntuneDeviceOs.WINDOWS) {
    return device.trustType === DeviceTrustTypes.AZURE_AD;
  }
  if (device.operatingSystem === IntuneDeviceOs.MAC) {
    return (
      device.trustType === DeviceTrustTypes.AZURE_AD ||
      device.trustType === DeviceTrustTypes.WORKPLACE
    );
  }
  return false;
}
