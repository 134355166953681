import {
  Box,
  CircularProgress,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Table,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  BsEnvelopeExclamation,
  BsEnvelopeCheck,
  BsCheckCircle,
} from "react-icons/bs";
import { useCompanyDeviceOnboardingChecks } from "../../../../../../../hooks/users.hooks";
import {
  AtomusEmailCategory,
  ReadyToOnboardStatuses,
  UserReadyToOnboardStatuses,
} from "../../../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import {
  EMAIL_CATEGORY_MAP,
  getUserBlockingReasons,
} from "../../../../../../../utils/emails.utils";
import { UserTableContext } from "../../../UserTableContext";
import { splitFilter } from "../../../../../../../utils/utils";

interface IRecipientStatusProps {
  userId: string;
  tenantId: string;
  userStatus?: UserReadyToOnboardStatuses;
  companyStatus?: Pick<
    ReadyToOnboardStatuses,
    "aegisAppConsentGranted" | "companyGroupFound"
  >;
}

export default function RecipientStatusIcon({
  userId,
  tenantId,
}: Readonly<IRecipientStatusProps>) {
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const { selectedEmails, setUserBlockedEmails } = useContext(UserTableContext);

  const {
    data: onboardingCheckData,
    isLoading,
    isRefetching,
    isError,
    error,
  } = useCompanyDeviceOnboardingChecks(tenantId);

  const userStatus = useMemo(() => {
    return onboardingCheckData?.userStatuses[userId];
  }, [onboardingCheckData?.userStatuses, userId]);

  const companyStatus = useMemo(() => {
    return {
      aegisAppConsentGranted:
        onboardingCheckData?.aegisAppConsentGranted ?? null,
      companyGroupFound: onboardingCheckData?.companyGroupFound ?? null,
    };
  }, [
    onboardingCheckData?.aegisAppConsentGranted,
    onboardingCheckData?.companyGroupFound,
  ]);

  const blockingReasons = useMemo((): Record<string, string | null> => {
    const reasons: Record<string, string | null> = {};
    // get the only emails that might cause a block
    const blockingEmails = selectedEmails.filter(
      (emailCategory) =>
        emailCategory.startsWith("custom-") ||
        emailCategory === "device-onboarding"
    );
    for (const emailCategory of blockingEmails) {
      if (userStatus && companyStatus) {
        // get the blocking reasons for this email for this user
        const thisEmailReasons = getUserBlockingReasons(
          userStatus,
          companyStatus,
          [emailCategory]
        );
        if (thisEmailReasons) {
          reasons[emailCategory] = thisEmailReasons;
        }
        if (isError) {
          reasons[emailCategory] = error.message;
        }
      }
    }
    if (Object.values(reasons).some((reason) => reason !== null)) {
      setIsBlocked(true);
    } else {
      setIsBlocked(false);
    }
    return reasons;
  }, [companyStatus, error?.message, isError, selectedEmails, userStatus]);

  // update user blocked emails depending on this user's status
  useEffect(() => {
    if (isBlocked === false) {
      // remove this userId
      setUserBlockedEmails((curr) =>
        curr.filter((thisUserId) => userId !== thisUserId)
      );
    } else {
      // add this userId
      setUserBlockedEmails((curr) => [
        ...(curr.filter((thisUserId) => userId !== thisUserId) ?? []),
        userId,
      ]);
    }
  }, [isBlocked, userId, setUserBlockedEmails]);

  const [nonBlockedEmailCategories, blockedEmailCategories] = useMemo(
    () =>
      splitFilter(
        selectedEmails,
        (emailCategory) =>
          !blockingReasons[emailCategory as AtomusEmailCategory]
      ),
    [blockingReasons, selectedEmails]
  );

  if (isLoading || isRefetching) {
    return <CircularProgress isIndeterminate size="20px" ml="17px" />;
  }

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          ml="8px"
          isDisabled={selectedEmails.length === 0}
          variant="ghost"
          icon={
            !isBlocked ? (
              <BsEnvelopeCheck color="green" size="18px" />
            ) : (
              <BsEnvelopeExclamation color="red" size="18px" />
            )
          }
          aria-label="Show email statuses"
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          {isBlocked || isError
            ? "Some emails cannot be sent:"
            : "All emails are ready to send:"}
        </PopoverHeader>

        <PopoverBody>
          <Box maxH="200px" overflowY="scroll">
            <Table variant="simple" size="sm">
              <Tbody>
                {isBlocked &&
                  blockedEmailCategories.map((emailCategory) => (
                    <Tr key={emailCategory}>
                      <Td>{`Cannot send ${
                        EMAIL_CATEGORY_MAP[emailCategory as AtomusEmailCategory]
                      }`}</Td>
                      <Td>{blockingReasons[emailCategory]}</Td>
                    </Tr>
                  ))}
                {nonBlockedEmailCategories?.map((emailCategory) => (
                  <Tr key={emailCategory}>
                    <Td>{EMAIL_CATEGORY_MAP[emailCategory]}</Td>
                    <Td textAlign="center">
                      <Flex ml="40px">
                        <BsCheckCircle color="green" />
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
