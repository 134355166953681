import { useMsal } from "@azure/msal-react";
import { ReactNode, useContext, useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import AuthContext from "./contexts/AuthContext";
import LoginPage from "./Pages/LoginPage";
import MsalRedirectPage from "./Pages/MsalRedirectPage/MsalRedirectPage";
import TenantOverviewPage from "./Pages/TenantOverviewPage";
import { TenantContextProvider } from "./Pages/TenantOverviewPage/TenantContext";
import TenantsListPage from "./Pages/TenantsListPage";
import { MicrosoftTokenProvider } from "./Providers/MicrosoftTokenProvider";
import { Box } from "@chakra-ui/react";

export default function App() {
  const { setIsGov } = useContext(AuthContext);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const cloudInstance = searchParams.get("cloudInstance");
    if (cloudInstance) {
      setIsGov(cloudInstance === "gov");
    }
  }, [setIsGov]);

  const AuthTemplate = ({ children }: { children: ReactNode }) => {
    const { instance } = useMsal();
    const location = useLocation();
    if (location.pathname === "/msal-redirect") {
      return <></>;
    }
    if (instance.getActiveAccount()) {
      return <>{children}</>;
    }
    return <></>;
  };

  const UnAuthTemplate = ({ children }: { children: ReactNode }) => {
    const { instance } = useMsal();
    const location = useLocation();
    if (location.pathname === "/msal-redirect") {
      return <></>;
    }
    if (instance.getActiveAccount()) {
      return <></>;
    }
    return <>{children}</>;
  };

  return (
    <BrowserRouter>
      <MicrosoftTokenProvider>
        <AuthTemplate>
          <Routes>
            <Route path="/tenants" element={<TenantsListPage />} />
            <Route
              path="/tenants/:tenantId"
              element={<Navigate to={`./devices`} />}
            />
            <Route
              path="/tenants/:tenantId/:tabId"
              element={
                <TenantContextProvider>
                  <Box maxHeight="100vh" overflowY="hidden">
                    <TenantOverviewPage />
                  </Box>
                </TenantContextProvider>
              }
            />
            <Route path="/msal-redirect" element={<MsalRedirectPage />} />
            <Route path="*" element={<Navigate to="/tenants" />} />
          </Routes>
        </AuthTemplate>
        <UnAuthTemplate>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/msal-redirect" element={<MsalRedirectPage />} />
            <Route
              path="*"
              element={
                <Navigate
                  to={`/login?state=${encodeURIComponent(
                    window.location.pathname + window.location.search
                  )}`}
                />
              }
            />
          </Routes>
        </UnAuthTemplate>
      </MicrosoftTokenProvider>
    </BrowserRouter>
  );
}
