import { InfoIcon } from "@chakra-ui/icons";
import {
  Text,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Progress,
  Center,
  Button,
  useToast,
  Icon,
  Flex,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import { useContext, useEffect, useMemo } from "react";
import {
  useAtomusGroupsStatus,
  useAtomusGroupUsers,
  useGroupRoles,
} from "../../../../../hooks/groups.hooks";
import useMicrosoftToken from "../../../../../hooks/tokens.hooks";
import { toastError } from "../../../../../Providers/ToastProvider";
import { removeGroupUser } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { ExternalAzureUserWithId } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { isAtomusEmail } from "../../../../../utils/utils";
import { GroupMgmtContext } from "../GroupMgmtContext";

interface IGroupSectionProps {
  tenantId: string;
  groupId: string;
  assignedUsers: ExternalAzureUserWithId[];
  setAssignedUsers: React.Dispatch<
    React.SetStateAction<ExternalAzureUserWithId[]>
  >;
  description?: string;
}

export default function GroupSection({
  tenantId,
  groupId,
  assignedUsers,
  setAssignedUsers,
  description,
}: IGroupSectionProps) {
  const {
    buttonsLoading,
    setButtonsLoading,
    setUnassignedAtomusUsers,
    setUnassignedOtherUsers,
  } = useContext(GroupMgmtContext);
  const usersQueryResult = useAtomusGroupUsers(tenantId, groupId);
  const rolesQueryResult = useGroupRoles(tenantId, groupId);
  const { getInternalApiToken } = useMicrosoftToken();
  const { data } = useAtomusGroupsStatus(tenantId);
  const toast = useToast();

  useEffect(() => {
    if (usersQueryResult.data && usersQueryResult.isFetchedAfterMount) {
      setAssignedUsers(usersQueryResult.data);
    }
  }, [
    usersQueryResult.data,
    usersQueryResult.isFetchedAfterMount,
    setAssignedUsers,
  ]);

  const handleRemoveUser = async (user: ExternalAzureUserWithId) => {
    try {
      setButtonsLoading(true);
      const token = await getInternalApiToken();
      await removeGroupUser(
        token,
        tenantId,
        groupId,
        user.id,
        data?.dashboardGroupId === groupId ? true : undefined
      );
      setAssignedUsers((prevUsers) =>
        prevUsers.filter((unassignedUser) => unassignedUser.id !== user.id)
      );
      if (isAtomusEmail(user.userPrincipalName)) {
        setUnassignedAtomusUsers((prevUsers) =>
          [...prevUsers, user].sort((a, b) =>
            a.displayName > b.displayName ? 1 : -1
          )
        );
      } else {
        setUnassignedOtherUsers((prevUsers) =>
          [...prevUsers, user].sort((a, b) =>
            a.displayName > b.displayName ? 1 : -1
          )
        );
      }
      setButtonsLoading(false);
    } catch (err) {
      setButtonsLoading(false);
      toastError(toast, err);
    }
  };

  const aadRolesText = useMemo(
    () =>
      rolesQueryResult.data?.aadRoles?.length
        ? rolesQueryResult.data?.aadRoles.join(", ")
        : "None",
    [rolesQueryResult.data?.aadRoles]
  );

  const armRolesText = useMemo(
    () =>
      rolesQueryResult.data?.armRoles?.length
        ? rolesQueryResult.data?.armRoles.join(", ")
        : "None",
    [rolesQueryResult.data?.armRoles]
  );

  if (usersQueryResult?.isFetching) {
    return <Progress margin="16px" isIndeterminate borderRadius="md" />;
  }

  if (usersQueryResult?.isError) {
    return <Center>{usersQueryResult.error.message}</Center>;
  }

  return (
    <>
      <Flex flexDir="column">
        {description && (
          <Text fontSize="12pt" as="i" m="10px">
            {description}
          </Text>
        )}
        <Flex flexDir="row">
          <Text fontSize="12pt" fontWeight="semibold" m="10px">
            Assigned Roles
          </Text>

          <Flex
            alignItems="center"
            cursor={!rolesQueryResult.isLoading ? "pointer" : undefined}
            alignSelf="center"
            m="5px"
          >
            <Tooltip
              as={Text}
              isDisabled={rolesQueryResult.isLoading}
              label={`AAD Roles: ${aadRolesText}.
            ARM Roles: ${armRolesText}.`}
            >
              <Icon
                as={InfoIcon}
                color={rolesQueryResult.isLoading ? "gray" : "blue.400"}
              />
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
      <Box
        overflowY="scroll"
        width="100%"
        minHeight="30px"
        maxHeight="200px"
        overflow="scroll"
        border="2px solid"
        borderColor="blue.500"
        p="5px"
        rounded="7px"
      >
        <Table
          variant="simple"
          marginBottom={6}
          __css={{ "table-layout": "fixed", width: "100%" }}
        >
          <Thead>
            <Tr>
              <Th width="40%">Name</Th>
              <Th width="40%">UPN</Th>
              <Th width="20%"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {assignedUsers.map((user) => (
              <Tr key={user.id}>
                <Td>{user.displayName}</Td>
                <Td>
                  <Box
                    maxWidth="100%"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    <Text as="span" whiteSpace="nowrap">
                      {user.userPrincipalName}
                    </Text>
                  </Box>
                </Td>
                <Td>
                  <Button
                    width={20}
                    colorScheme="red"
                    variant="ghost"
                    size="sm"
                    onClick={() => handleRemoveUser(user)}
                    isLoading={buttonsLoading}
                    isDisabled={buttonsLoading}
                  >
                    Remove
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </>
  );
}
