import {
  Box,
  Skeleton,
  HStack,
  Tag,
  Text,
  Tooltip,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  Center,
  useToast,
  forwardRef,
  TagProps,
} from "@chakra-ui/react";
import { useCallback, useContext, useState } from "react";
import { BsArrowRepeat } from "react-icons/bs";
import AuthContext from "../../../contexts/AuthContext";
import {
  useAccountSetupAccountInfo,
  useArcExpiration,
} from "../../../hooks/tenants.hooks";
import useMicrosoftToken from "../../../hooks/tokens.hooks";
import { toastError, toastSuccess } from "../../../Providers/ToastProvider";
import { runAccountSetup } from "../../../services/atomus-internal-apis/atomus-internal-apis.service";

export default function ArcSection({ tenantId }: { tenantId: string }) {
  const arcExpirationQuery = useArcExpiration(tenantId);
  const { role } = useContext(AuthContext);
  const accountInfoQuery = useAccountSetupAccountInfo(tenantId);
  const getArcColorScheme = () => {
    if (arcExpirationQuery.isError) return "red";
    if (!arcExpirationQuery.data) return "gray";
    const arcData = arcExpirationQuery.data;
    if (arcData.isExpired) return "red";
    const expDate = new Date(arcData.expirationDate);
    expDate.setDate(expDate.getDate() - 30);
    if (expDate.valueOf() - new Date().valueOf() <= 0) return "yellow";
    return "green";
  };
  const [isRenewing, setIsRenewing] = useState(false);
  const { getInternalApiToken } = useMicrosoftToken();
  const toast = useToast();
  const [renewErrors, setRenewErrors] = useState("");

  const handleRenew = useCallback(
    async (onClose: () => void) => {
      if (!accountInfoQuery.data?.location) {
        return;
      }
      setIsRenewing(true);
      try {
        const token = await getInternalApiToken();
        const stepsRes = await runAccountSetup(
          token,
          tenantId,
          { location: accountInfoQuery.data.location },
          ["uploadArcInfoToAppConfig"]
        );
        if (stepsRes.success) {
          arcExpirationQuery.refetch({ throwOnError: true });
          toastSuccess(toast, "Successfully renewed arc secret");
          onClose();
        } else {
          toastError(toast, "Error renewing arc secret");
          setRenewErrors(
            Object.entries(stepsRes.errors)
              .map(([key, value]) => `${key}: ${value}`)
              .join(", ")
          );
        }
      } catch (error) {
        console.error(error);
        toastError(toast, "Unexpected error while renewing arc secret");
      }
      setIsRenewing(false);
    },
    [
      accountInfoQuery.data?.location,
      arcExpirationQuery,
      getInternalApiToken,
      tenantId,
      toast,
    ]
  );

  const canRenew =
    arcExpirationQuery.data?.isExpired &&
    accountInfoQuery.data?.location &&
    role === "securityAdmin";

  const ExpirationTag = forwardRef<TagProps, "div">((props, ref) => (
    <Tag
      colorScheme={getArcColorScheme()}
      size="sm"
      _hover={!canRenew ? undefined : { cursor: "pointer" }}
      ref={ref}
      {...props}
    >
      {(() => {
        let date = new Date();
        if (!!arcExpirationQuery.data) {
          date = new Date(arcExpirationQuery.data.expirationDate);
        }
        return date.toDateString();
      })()}
    </Tag>
  ));

  return (
    <Box>
      <Text fontSize="xs" fontWeight="bold">
        Arc expiration date
      </Text>
      {!arcExpirationQuery.isError ? (
        <Skeleton isLoaded={!!arcExpirationQuery.data}>
          <HStack>
            {canRenew ? (
              <Popover>
                {({ onClose }) => (
                  <>
                    <PopoverTrigger>
                      <ExpirationTag />
                    </PopoverTrigger>
                    <PopoverContent width="fit-content">
                      <PopoverArrow />
                      <Center padding="12px">
                        <Button
                          size="xs"
                          variant="outline"
                          colorScheme="blue"
                          leftIcon={<BsArrowRepeat />}
                          isLoading={isRenewing}
                          onClick={() => handleRenew(onClose)}
                        >
                          Renew Arc Secret
                        </Button>
                      </Center>
                      {renewErrors && <Text color="red">{renewErrors}</Text>}
                    </PopoverContent>
                  </>
                )}
              </Popover>
            ) : (
              <ExpirationTag />
            )}
          </HStack>
        </Skeleton>
      ) : (
        <Tooltip label={arcExpirationQuery.error.message}>
          <Tag colorScheme={getArcColorScheme()} size="sm">
            Error
          </Tag>
        </Tooltip>
      )}
    </Box>
  );
}
