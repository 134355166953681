import { IconBaseProps } from "react-icons/lib";
import {
  BsWindows,
  BsApple,
  BsUbuntu,
  BsPhone,
  BsAndroid,
  BsLaptop,
} from "react-icons/bs";
import { Device } from "../../services/atomus-internal-apis/atomus-internal-apis.types";

export function DeviceIcon({
  device,
  ...props
}: { device: Device } & IconBaseProps) {
  const os = device.operatingSystem.toLowerCase();
  if (os.includes("windows")) {
    return <BsWindows {...props} />;
  }
  if (os.includes("mac")) {
    return <BsApple {...props} />;
  }
  if (os.includes("linux")) {
    return <BsUbuntu {...props} />;
  }
  if (os.includes("iphone")) {
    return <BsPhone {...props} />;
  }
  if (os.includes("android")) {
    return <BsAndroid {...props} />;
  }
  return <BsLaptop {...props} />;
}
