import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  Heading,
  Button,
  Text,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  IconButton,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { isEqual } from "lodash";
import {
  BsCheckLg,
  BsDashCircle,
  BsDownload,
  BsPlusLg,
  BsXLg,
} from "react-icons/bs";
import { UsbAccessRequest } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { StateSetter } from "../../../../../types/types";
import { splitFilter } from "../../../../../utils/utils";
import { toastError } from "../../../../../Providers/ToastProvider";

const MAC_SCRIPT_DATA = {
  url: "https://uhgodyxkkusljmqgamhq.supabase.co/storage/v1/object/public/atomus-scripts/AtomusMacUSBCollection.sh?t=2024-11-08T18%3A37%3A39.915Z",
  name: "AtomusMacUSBCollection.sh",
};
const WINDOWS_SCRIPT_DATA = {
  url: "https://uhgodyxkkusljmqgamhq.supabase.co/storage/v1/object/public/atomus-scripts/AtomusWindowsUSBCollection.ps1?t=2024-11-08T18%3A37%3A56.680Z",
  name: "AtomusWindowsUSBCollection.ps1",
};

interface IManuallyEntryModalProps {
  platform: "MacOS" | "Windows";
  isOpen: boolean;
  onClose: () => void;
  existingRequests: UsbAccessRequest[];
  manualEntryRequests: Pick<
    UsbAccessRequest,
    "serialNumbers" | "friendlyName"
  >[];
  setManualEntryRequests: StateSetter<
    Pick<UsbAccessRequest, "serialNumbers" | "friendlyName">[]
  >;
  handleConfirm: () => Promise<void>;
}

export default function ManualEntryModal({
  platform,
  isOpen,
  onClose,
  handleConfirm,
  existingRequests,
  manualEntryRequests,
  setManualEntryRequests,
}: Readonly<IManuallyEntryModalProps>) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toastProvider = useToast();

  const confirmIsDisabled: { status: boolean; blockingReason: string | null } =
    useMemo(() => {
      const duplicateSerialStatus = {
        status: false,
        blockingReason: "Duplicate serial number values found",
      };
      // check for duplicate serials in manual requests
      for (const manualRequest of manualEntryRequests) {
        const theseSerials = manualRequest.serialNumbers;
        const [matchingRequests, otherRequests] = splitFilter(
          manualEntryRequests,
          (request) => isEqual(request.serialNumbers, theseSerials)
        );
        // exact matching serial numbers array
        if (matchingRequests.length > 1) {
          return duplicateSerialStatus;
        }
        // one serial in another request equals this serial
        for (const otherRequest of otherRequests) {
          if (
            theseSerials.some((serial) =>
              otherRequest.serialNumbers.includes(serial)
            )
          ) {
            return duplicateSerialStatus;
          }
        }
      }
      // get serial numbers from all existing  requests
      const existingSerialNumbers = new Set(
        existingRequests?.flatMap((request) => request.serialNumbers) || []
      );
      if (
        // check for any empty values
        manualEntryRequests.some(
          (request) =>
            request.friendlyName.length === 0 ||
            request.serialNumbers.length === 0 ||
            request.serialNumbers.some((serial) => serial === "")
        )
      ) {
        return {
          status: false,
          blockingReason:
            "All device names and serial numbers must not be empty",
        };
      } else if (
        // check existing requests for duplicates
        manualEntryRequests?.some((manualRequest) =>
          manualRequest.serialNumbers.some((serialNumber) =>
            existingSerialNumbers.has(serialNumber)
          )
        )
      ) {
        return duplicateSerialStatus;
      }
      return { status: true, blockingReason: null };
    }, [existingRequests, manualEntryRequests]);

  const handleDownloadScript = async () => {
    const response = await fetch(
      platform === "MacOS" ? MAC_SCRIPT_DATA.url : WINDOWS_SCRIPT_DATA.url
    );
    if (!response.ok) {
      toastError(
        toastProvider,
        "Failed to download script. Contact success@atomuscyber.com if this issue persists."
      );
      return;
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download =
      platform === "MacOS" ? MAC_SCRIPT_DATA.name : WINDOWS_SCRIPT_DATA.name;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const handleInputChange = (
    index: number,
    field: "friendlyName" | "serialNumbers",
    value: string
  ) => {
    // insert this updated value @ index
    const insertValue =
      field === "friendlyName"
        ? value
        : value.split(",").map((serial) => serial.trim());
    const updatedData = [
      ...manualEntryRequests.slice(0, index),
      { ...manualEntryRequests[index], [field]: insertValue },
      ...manualEntryRequests.slice(index + 1),
    ];
    setManualEntryRequests(updatedData);
  };

  const handleAddRow = () => {
    setManualEntryRequests([
      ...manualEntryRequests,
      { friendlyName: "", serialNumbers: [] },
    ]);
  };

  const handleDeleteRow = (index: number) => {
    const updatedData = manualEntryRequests.filter((_, i) => i !== index);
    setManualEntryRequests(updatedData);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalBody padding="28px">
          <Flex flexDir="column" gap="16px" alignItems="flex-start">
            <Heading fontWeight="semibold" fontSize="14pt">
              <Text align="left">
                {`Enter USB serial numbers to whitelist on ${platform}`}
              </Text>
            </Heading>
            {platform === "Windows" && (
              <Text fontWeight={300} fontSize="10pt">
                {
                  "For partitioned USB drives, insert the serial numbers separated by commas (as generated by the script). i.e. ABCD1234&0,ABCD1234&1"
                }
              </Text>
            )}
            <Button
              size="sm"
              variant="ghost"
              leftIcon={<BsDownload />}
              onClick={handleDownloadScript}
            >
              <Text fontSize="10pt" mr="10px" fontWeight={300}>
                Download collection script for {platform}
              </Text>
            </Button>

            {/* Editable Table */}
            <Table variant="simple" size="sm" mt="10px" width="97%">
              <Thead>
                <Tr>
                  <Th>Friendly Name</Th>
                  <Th>Serial Number</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {manualEntryRequests.map((row, index) => (
                  <Tr key={index}>
                    <Td>
                      <Input
                        value={row.friendlyName}
                        placeholder="USB Name"
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "friendlyName",
                            e.target.value
                          )
                        }
                      />
                    </Td>
                    <Td>
                      <Input
                        value={row.serialNumbers.join(",")}
                        placeholder="USB Serial"
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "serialNumbers",
                            e.target.value
                          )
                        }
                      />
                    </Td>
                    <Td>
                      <IconButton
                        isDisabled={manualEntryRequests.length === 1}
                        aria-label="delete-row"
                        variant="ghost"
                        icon={<BsDashCircle />}
                        colorScheme="red"
                        size="sm"
                        onClick={() => handleDeleteRow(index)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>
          <Flex flexDir="row-reverse" mt="10px" mr="15px">
            <Button
              width="100px"
              onClick={handleAddRow}
              leftIcon={<BsPlusLg />}
              size="sm"
              variant="outline"
            >
              Add Row
            </Button>
          </Flex>
          {/* Confirm and Cancel buttons */}
          <Flex
            flexDir="row"
            gap="12px"
            mt="30px"
            mr="15px"
            justifyContent="flex-end"
          >
            <Tooltip
              isDisabled={confirmIsDisabled.status}
              label={confirmIsDisabled.blockingReason}
            >
              <Button
                width="100px"
                size="sm"
                isLoading={isLoading}
                isDisabled={!confirmIsDisabled.status}
                colorScheme="blue"
                variant="outline"
                leftIcon={<BsCheckLg />}
                onClick={async () => {
                  setIsLoading(true);
                  await handleConfirm();
                  onClose();
                  setIsLoading(false);
                }}
              >
                Confirm
              </Button>
            </Tooltip>
            <Button
              width="100px"
              isDisabled={isLoading}
              size="sm"
              variant="outline"
              colorScheme="red"
              leftIcon={<BsXLg />}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
