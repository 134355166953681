import { Checkbox, Td, Tr, useToast } from "@chakra-ui/react";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { AegisUser } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import UserAzureInfo from "./UserAzureInfo";
import UserBasicInfo from "./UserBasicInfo";
import UserComplianceInfo from "./UserComplianceInfo";
import UserActionsMenu from "./UserActionsMenu/UserActionsMenu";
import UserDeviceMfaInfo from "./UserDeviceMfaInfo";
import UserEmailTimeline from "./UserEmailTimeline";
import { UserTypes } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.constants";
import UserNoAtomusInfo from "./UserNoAzureInfo";
import UserAttributesSection from "./UserAttributesSection";
import { UserRowContext } from "./UserRowContext";
import { UserTableContext } from "../UserTableContext";

export function UserRow({
  aegisUser,
  tenantId,
  deviceDetailsModalOnOpen,
  setModalDeviceId,
}: Readonly<{
  aegisUser: AegisUser;
  tenantId: string;
  deviceDetailsModalOnOpen: () => void;
  setModalDeviceId: Dispatch<SetStateAction<string | null>>;
}>) {
  const [azureInfoError, setAzureInfoError] = useState<Error | null>(null);
  const { setAegisUser } = useContext(UserRowContext);
  const { selectedUserIds, setSelectedUserIds } = useContext(UserTableContext);

  const toast = useToast();

  useEffect(() => {
    setAegisUser(aegisUser);
  }, [aegisUser, setAegisUser]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const userIdsCopy = { ...selectedUserIds };
    if (!isChecked) {
      // remove user id if unchecked
      delete userIdsCopy[aegisUser.id];
    } else {
      // add user if checked
      userIdsCopy[aegisUser.id] = aegisUser.email;
    }
    setSelectedUserIds(userIdsCopy);
  };

  return (
    <Tr key={aegisUser.id}>
      <Td>
        <Checkbox
          isDisabled={!aegisUser.active}
          isChecked={Object.keys(selectedUserIds).includes(aegisUser.id)}
          onChange={handleCheckboxChange}
        />
      </Td>
      <UserBasicInfo
        aegisUser={aegisUser}
        error={azureInfoError}
        toast={toast}
      />

      {aegisUser.active && aegisUser.userType !== UserTypes.OFFLINE ? (
        <>
          <UserAttributesSection
            tenantId={tenantId}
            deviceDetailsModalOnOpen={deviceDetailsModalOnOpen}
            setModalDeviceId={setModalDeviceId}
          />
          <UserAzureInfo
            setError={setAzureInfoError}
            userId={aegisUser.id}
            tenantId={tenantId}
          />
        </>
      ) : (
        <UserNoAtomusInfo />
      )}
      <UserComplianceInfo aegisUser={aegisUser} />
      <UserDeviceMfaInfo tenantId={tenantId} username={aegisUser.username} />
      <UserEmailTimeline userId={aegisUser.id} tenantId={tenantId} />
      <UserActionsMenu user={aegisUser} tenantId={tenantId} />
    </Tr>
  );
}
