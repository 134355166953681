import { Box, Button, Center, Flex, Progress, Tag } from "@chakra-ui/react";
import { useContext } from "react";
import AccountSetupInfo from "./AccountSetupInfo";
import AccountSetupRunResult from "./AccountSetupRunResult";
import OrganizationalBrandingSection from "./OrganizationalBrandingSection";
import AccountSetupUsersSection from "./AccountSetupUsersSection";
import MacOSDefenderSection from "./MacOSDefenderSection";
import {
  useAccountSetupAccountInfo,
  useAccountSetupStatuses,
} from "../../../hooks/tenants.hooks";
import { AccountSetupContext } from "./AccountSetupContext";
import StepStatusSection from "./StepStatusSection";
import LocationTooltip from "./LocationTooltip";
import LastScrollableContainer from "../../../Components/LastScrollableContainer";

interface IAccountSetupSectionProps {
  tenantId: string;
}

export default function AccountSetupSection({
  tenantId,
}: Readonly<IAccountSetupSectionProps>) {
  const { accountInfo, runResult, stepsAreRunning, runSteps } =
    useContext(AccountSetupContext);

  const {
    isFetching: infoIsFetching,
    isError: infoIsError,
    error: infoError,
    refetch: infoRefetch,
  } = useAccountSetupAccountInfo(tenantId);
  const {
    isFetching: statusesAreFetching,
    isError: statusesAreError,
    error: statusesError,
    data: statuses,
    refetch: statusesRefetch,
  } = useAccountSetupStatuses(tenantId);

  if (infoIsError || statusesAreError) {
    return (
      <Center>
        <Flex flexDir="column">
          {statusesAreError ||
            (infoIsError && (
              <Tag colorScheme="red">
                {(statusesError ?? infoError).message}
              </Tag>
            ))}
          <Button
            size="sm"
            m="10px"
            variant="ghost"
            colorScheme="blue"
            isLoading={statusesAreFetching || infoIsFetching}
            onClick={async () => {
              await Promise.all([
                infoRefetch({ throwOnError: true }),
                statusesRefetch({ throwOnError: true }),
              ]);
            }}
          >
            Refresh
          </Button>
        </Flex>
      </Center>
    );
  }

  return (
    <LastScrollableContainer>
      <Flex marginY={4} flexDir="column" gap="24px" padding={4}>
        {!infoIsFetching && accountInfo && <AccountSetupInfo />}
        {(!accountInfo ||
          !statuses ||
          infoIsFetching ||
          statusesAreFetching) && (
          <Progress isIndeterminate borderRadius="md" />
        )}
        {!infoIsFetching &&
          !statusesAreFetching &&
          accountInfo &&
          statuses?.length === 0 && (
            <Box width="fit-content">
              <LocationTooltip>
                <Button
                  variant="outline"
                  colorScheme="blue"
                  isLoading={stepsAreRunning}
                  onClick={() => runSteps(true, [])}
                  isDisabled={!accountInfo.location}
                >
                  Run full Account Setup
                </Button>
              </LocationTooltip>
            </Box>
          )}
        {!infoIsFetching &&
          !statusesAreFetching &&
          accountInfo &&
          statuses &&
          statuses.length > 0 && (
            <>
              <OrganizationalBrandingSection tenantId={tenantId} />
              <AccountSetupUsersSection tenantId={tenantId} />
              <MacOSDefenderSection />
              {runResult && <AccountSetupRunResult />}
              <StepStatusSection tenantId={tenantId} />
            </>
          )}
      </Flex>
    </LastScrollableContainer>
  );
}
