import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Button,
} from "@chakra-ui/react";
import { useContext } from "react";
import AuthContext from "../../../contexts/AuthContext";
import { UserTableContextProvider } from "./UserTable/UserTableContext";
import GroupMgmtSection from "./GroupMgmtSection";
import { GroupManagementContextProvider } from "./GroupMgmtSection/GroupMgmtContext";
import UsersTable from "./UserTable";
import { createQueryClient } from "../../../utils/sections.utils";
import { QueryClientProvider } from "react-query";

interface IUsersSectionProps {
  tenantId: string;
}

const usersQueryClient = createQueryClient();
export default function UsersSection({
  tenantId,
}: Readonly<IUsersSectionProps>) {
  const { role } = useContext(AuthContext);

  const refetchData = () => {
    usersQueryClient.refetchQueries();
  };

  return (
    <QueryClientProvider client={usersQueryClient}>
      <UserTableContextProvider>
        {role === "companyAdmin" ? (
          <UsersTable tenantId={tenantId} />
        ) : (
          <Tabs variant="enclosed" colorScheme="blue" isLazy>
            <Button
              size="sm"
              colorScheme="blue"
              variant="ghost"
              right={0}
              marginRight={4}
              position="absolute"
              onClick={refetchData}
            >
              Refresh
            </Button>
            <TabList>
              <Tab>Company Users</Tab>
              <Tab>Admin Users</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <UsersTable tenantId={tenantId} />
              </TabPanel>
              <TabPanel>
                <GroupManagementContextProvider>
                  <GroupMgmtSection tenantId={tenantId} />
                </GroupManagementContextProvider>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </UserTableContextProvider>
    </QueryClientProvider>
  );
}
