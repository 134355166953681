import {
  Popover,
  PopoverTrigger,
  IconButton,
  Center,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Box,
  Table,
  Tbody,
  Td,
  Tr,
  Thead,
  Th,
  HStack,
} from "@chakra-ui/react";
import { SetStateAction, Dispatch, useContext } from "react";
import { UserRowContext } from "../../UserRowContext";
import { DeviceIcon } from "../../../../../../../Components/DeviceIcon";
import { UserTableContext } from "../../../UserTableContext";

type IUserAssociatedDevicesOptionsProps = Readonly<{
  tenantId: string;
  deviceDetailsModalOnOpen: () => void;
  setModalDeviceId: Dispatch<SetStateAction<string | null>>;
}>;

export default function UserAssociatedDevicesOptions({
  tenantId,
  deviceDetailsModalOnOpen,
  setModalDeviceId,
}: IUserAssociatedDevicesOptionsProps) {
  const { aegisUser } = useContext(UserRowContext);
  const { userDeviceMap } = useContext(UserTableContext);
  const userDevices = userDeviceMap[aegisUser?.cloudId ?? ""] || [];

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          aria-label="associated devices"
          variant="ghost"
          icon={
            <Center>
              <Box fontSize="sm" fontWeight="bold">
                {userDevices?.length}
              </Box>
            </Center>
          }
        />
      </PopoverTrigger>
      <PopoverContent width="320px" maxHeight="300px" overflowY="auto">
        <PopoverArrow />
        <PopoverCloseButton m="5px" />
        <PopoverBody>
          <Center mt="5px" mb="5px">
            {userDevices?.length === 0 ? (
              <Box>No associated devices</Box>
            ) : (
              <Table variant="simple" size="sm">
                <Tbody>
                  <Thead textAlign="center">
                    <Tr>
                      <Th>Associated Devices</Th>
                    </Tr>
                  </Thead>
                  {userDevices?.map((device) => (
                    <Tr
                      key={device.id}
                      onClick={() => {
                        setModalDeviceId(device.id);
                        deviceDetailsModalOnOpen();
                      }}
                      cursor="pointer"
                      _hover={{ bg: "gray.50" }}
                    >
                      <Td>
                        <HStack spacing={2}>
                          <DeviceIcon device={device} size="1rem" />
                          <Box>{device.displayName}</Box>
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </Center>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
